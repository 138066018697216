var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate, field } from '../../../../ChronoGraph/replica/Entity.js';
import { dateConverter, model_field } from '../../../chrono/ModelFieldAtom.js';
import { isDateFinite } from "../../../util/Constants.js";
import { HasSubEventsMixin } from "../scheduler_basic/HasSubEventsMixin.js";
import { ConstrainedScheduleMixin } from "./ConstrainedScheduleMixin.js";
import { HasPercentDoneMixin } from './HasPercentDoneMixin.js';
//---------------------------------------------------------------------------------------------------------------------
/**
 * This mixin provides the constraint-based scheduling. Event is scheduled according to the set of _constraints_
 * which can be applied to start date or end date.
 *
 * Scheduling by constraints for an event can be disabled by setting its [[manuallyScheduled]] flag to `true`, which will delegate to previous behavior.
 *
 * The constraint is represented with the [[DateInterval]] class, which indicates the "allowed" interval for the
 * point being constrained.
 *
 * Scheduling by constraints algorithm
 * ---------------------------------
 *
 * Constraints for start date are accumulated in the [[startDateConstraintIntervals]] field of event itself and in the
 * [[ConstrainedScheduleMixin.startDateConstraintIntervals]] field of its [[earlySchedule]].
 * Constraints for end date are accumulated in the [[endDateConstraintIntervals]] field of event itself and in the
 * [[ConstrainedScheduleMixin.endDateConstraintIntervals]] field of its [[earlySchedule]].
 *
 * This mixin does not define where the constraints for the event comes from. The constraints are calculated in the event's schedule field
 * calculation methods, (like [[ConstrainedScheduleMixin.calculateStartDateConstraintIntervals]]) which just return empty arrays. Some other mixins
 * may override those methods and can generate actual constraints (the [[ConstrainedByDependenciesEventMixin]] is an example).
 * The "early" fields contains the constraints which are related to scheduling event in the as-soon-as-possible manner.
 * The fields w/o "early" prefix contains the constraints which do not relate to the ASAP scheduling.
 *
 * "Early" and "normal" constraints for every date are combined, then intersected, which gives "combined" constraining interval.
 *
 * So at this point we have a "combined" constraining interval for start date and for end date.
 *
 * Then, the interval for start date is shifted on the event duration to the right and this gives an additional constraint for the
 * end date. The similar operation is done with the interval for the end date.
 *
 * After intersection with those additional intervals we receive the final constraining interval for both dates. Since we
 * are using the ASAP scheduling, we just pick the earliest possible date.
 *
 * If any of intervals is empty then we consider it as scheduling conflict, and [[EngineReplica.reject|reject]] the transaction.
 *
 */
export class ConstrainedEarlyEventMixin extends Mixin([HasSubEventsMixin, HasPercentDoneMixin], (base) => {
    const superProto = base.prototype;
    class ConstrainedEarlyEventMixin extends base {
        /**
         * A [[ConstrainedScheduleMixin]] for this event, which is used for scheduling by constraints and dependencies.
         */
        get earlyPreSchedule() {
            if (this._earlyPreSchedule !== undefined)
                return this._earlyPreSchedule;
            const schedule = this.constructor.scheduleMixinClass.new();
            schedule.event = this;
            return this._earlyPreSchedule = schedule;
        }
        /**
         * A [[ConstrainedScheduleMixin]], which in the absence of late scheduling is just an alias for [[earlyPreSchedule]]
         */
        get earlySchedule() {
            return this.earlyPreSchedule;
        }
        enterGraph(replica) {
            super.enterGraph(replica);
            this.earlyPreSchedule.enterGraph(replica);
        }
        leaveGraph(replica) {
            this.earlyPreSchedule.leaveGraph(replica);
            super.leaveGraph(replica);
        }
        unlink() {
            this.earlyPreSchedule.unlink();
            super.unlink();
        }
        *calculateEarlyStartDate() {
            return yield this.earlySchedule.$.startDate;
        }
        *calculateEarlyEndDate() {
            return yield this.earlySchedule.$.endDate;
        }
        *calculateStartDateConstraintIntervals() {
            return [];
        }
        *calculateEndDateConstraintIntervals() {
            return [];
        }
        *calculateEarlyStartDateConstraintIntervals() {
            return [];
        }
        *calculateEarlyEndDateConstraintIntervals() {
            return [];
        }
        *calculateLateStartDateConstraintIntervals() {
            return [];
        }
        *calculateLateEndDateConstraintIntervals() {
            return [];
        }
        /**
         * The method defines whether the provided child event should be
         * taken into account when calculating this summary event [[earlyStartDate]].
         * Child events roll up their [[earlyStartDate]] values to their summary tasks.
         * So a summary task [[earlyStartDate]] date gets equal to its minimal child [[earlyStartDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default, the method returns `true` to include all child events data.
         * @param child Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildEarlyStartDate(child) {
            return true;
        }
        /**
         * The method defines whether the provided child event should be
         * taken into account when calculating this summary event [[earlyEndDate]].
         * Child events roll up their [[earlyEndDate]] values to their summary tasks.
         * So a summary task [[earlyEndDate]] gets equal to its maximal child [[earlyEndDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default, the method returns `true` to include all child events data.
         * @param child Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildEarlyEndDate(child) {
            return true;
        }
        /**
         * The method defines wether the provided child event should be
         * taken into account when calculating this summary event [[lateStartDate]].
         * Child events roll up their [[lateStartDate]] values to their summary tasks.
         * So a summary task [[lateStartDate]] date gets equal to its minimal child [[lateStartDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default the method returns `true` to include all child events data.
         * @param childEvent Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildLateStartDate(childEvent) {
            return true;
        }
        /**
         * The method defines wether the provided child event should be
         * taken into account when calculating this summary event [[lateEndDate]].
         * Child events roll up their [[lateEndDate]] values to their summary tasks.
         * So a summary task [[lateEndDate]] gets equal to its maximal child [[lateEndDate]].
         *
         * If the method returns `true` the child event is taken into account
         * and if the method returns `false` it's not.
         * By default the method returns `true` to include all child events data.
         * @param childEvent Child event to consider.
         * @returns `true` if the provided event should be taken into account, `false` if not.
         */
        *shouldRollupChildLateEndDate(childEvent) {
            return true;
        }
        *calculateStartDatePure() {
            const direction = yield this.$.effectiveDirection;
            let result;
            const manuallyScheduled = yield* this.isManuallyScheduled();
            // early exit if this mixin is not applicable, but only after(!) the direction check
            // this is because the `isConstrainedEarly` yield early constraint intervals, which are generally lazy,
            // depending on the direction
            // Note, that "simple" checks for `manuallyScheduled` and `unscheduled` goes prior the `isConstrainedEarly`
            // this is because `isConstrainedEarly` checks the start/end intervals, which may trigger a cycle in some
            // edge cases (see #7605), however, just being `manuallyScheduled` is enough for a task to skip those
            if (!manuallyScheduled && !(yield this.$.unscheduled) && (yield* this.isConstrainedAlongDirection(direction.direction))) {
                if (yield* this.hasSubEvents()) {
                    return yield* this.calculateMinChildrenStartDate();
                }
                result = yield this.pickDirectionSchedule(direction.direction).$.startDate;
            }
            return result || (yield* superProto.calculateStartDatePure.call(this));
        }
        *calculateStartDateProposed() {
            const direction = yield this.$.effectiveDirection;
            let result;
            const manuallyScheduled = yield* this.isManuallyScheduled();
            // early exit if this mixin is not applicable, but only after(!) the direction check
            // this is because the `isConstrainedEarly` yield early constraint intervals, which are generally lazy,
            // depending on the direction
            if (!manuallyScheduled && !(yield this.$.unscheduled) && (yield* this.isConstrainedAlongDirection(direction.direction))) {
                if (yield* this.hasSubEvents()) {
                    return yield* this.calculateMinChildrenStartDate();
                }
                result = yield this.pickDirectionSchedule(direction.direction).$.startDate;
                // is this code still needed?
                if (result && !isDateFinite(result)) {
                    throw new Error("Fix me");
                    // const baseSchedulingStartDate : Date                    = yield* superProto.calculateStartDateProposed.call(this)
                    // const earlyEffectiveStartDateInterval : DateInterval    = yield this.$.earlyEffectiveStartDateInterval
                    //
                    // if (earlyEffectiveStartDateInterval.containsDate(baseSchedulingStartDate)) return baseSchedulingStartDate
                    //
                    // return isDateFinite(earlyEffectiveStartDateInterval.endDate) ? earlyEffectiveStartDateInterval.endDate : baseSchedulingStartDate
                }
            }
            return result || (yield* superProto.calculateStartDateProposed.call(this));
        }
        *calculateEndDatePure() {
            const direction = yield this.$.effectiveDirection;
            let result;
            const manuallyScheduled = yield* this.isManuallyScheduled();
            // early exit if this mixin is not applicable, but only after(!) the direction check
            // this is because the `isConstrainedEarly` yield early constraint intervals, which are generally lazy,
            // depending on the direction
            if (!manuallyScheduled && !(yield this.$.unscheduled) && (yield* this.isConstrainedAlongDirection(direction.direction))) {
                if (yield* this.hasSubEvents()) {
                    return yield* this.calculateMaxChildrenEndDate();
                }
                result = yield this.pickDirectionSchedule(direction.direction).$.endDate;
            }
            return result || (yield* superProto.calculateEndDatePure.call(this));
        }
        *calculateEndDateProposed() {
            const direction = yield this.$.effectiveDirection;
            let result;
            const manuallyScheduled = yield* this.isManuallyScheduled();
            // early exit if this mixin is not applicable, but only after(!) the direction check
            // this is because the `isConstrainedEarly` yield early constraint intervals, which are generally lazy,
            // depending on the direction
            if (!manuallyScheduled && !(yield this.$.unscheduled) && (yield* this.isConstrainedAlongDirection(direction.direction))) {
                if (yield* this.hasSubEvents()) {
                    return yield* this.calculateMaxChildrenEndDate();
                }
                result = yield this.pickDirectionSchedule(direction.direction).$.endDate;
                // is this code still needed?
                if (result && !isDateFinite(result)) {
                    throw new Error("Fix me");
                    // const baseSchedulingEndDate : Date                  = yield* superProto.calculateEndDateProposed.call(this)
                    // const earlyEffectiveEndDateInterval : DateInterval  = yield this.$.effectiveEndDateInterval
                    //
                    // if (earlyEffectiveEndDateInterval.containsDate(baseSchedulingEndDate)) return baseSchedulingEndDate
                    //
                    // return isDateFinite(earlyEffectiveEndDateInterval.endDate) ? earlyEffectiveEndDateInterval.endDate : baseSchedulingEndDate
                }
            }
            return result || (yield* superProto.calculateEndDateProposed.call(this));
        }
        *isConstrainedAlongDirection(direction) {
            return yield* this.earlyPreSchedule.isConstrained();
        }
        pickDirectionSchedule(ownDirection) {
            return this.earlyPreSchedule;
        }
    }
    ConstrainedEarlyEventMixin.scheduleMixinClass = ConstrainedScheduleMixin;
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], ConstrainedEarlyEventMixin.prototype, "earlyStartDate", void 0);
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], ConstrainedEarlyEventMixin.prototype, "earlyEndDate", void 0);
    __decorate([
        field()
    ], ConstrainedEarlyEventMixin.prototype, "startDateConstraintIntervals", void 0);
    __decorate([
        field()
    ], ConstrainedEarlyEventMixin.prototype, "endDateConstraintIntervals", void 0);
    __decorate([
        calculate('earlyStartDate')
    ], ConstrainedEarlyEventMixin.prototype, "calculateEarlyStartDate", null);
    __decorate([
        calculate('earlyEndDate')
    ], ConstrainedEarlyEventMixin.prototype, "calculateEarlyEndDate", null);
    __decorate([
        calculate('startDateConstraintIntervals')
    ], ConstrainedEarlyEventMixin.prototype, "calculateStartDateConstraintIntervals", null);
    __decorate([
        calculate('endDateConstraintIntervals')
    ], ConstrainedEarlyEventMixin.prototype, "calculateEndDateConstraintIntervals", null);
    return ConstrainedEarlyEventMixin;
}) {
}
