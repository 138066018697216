import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import Delayable from '../../Core/mixin/Delayable.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import EventHelper from '../../Core/helper/EventHelper.js';
import GridLocation from '../../Grid/util/GridLocation.js';
import GlobalEvents from '../../Core/GlobalEvents.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
/**
 * @module Grid/feature/FillHandle
 */
/**
 * This feature adds a fill handle to a Grid range selection, which when dragged, fills the cells being dragged over
 * with values based on the values in the original selected range. This is similar to functionality normally seen in
 * various spreadsheet applications.
 *
 * {@inlineexample Grid/feature/FillHandle.js}
 *
 * Requires {@link Grid/view/Grid#config-selectionMode selectionMode.cell} to be activated.
 *
 * This feature is **disabled** by default
 *
 * ```javascript
 * const grid = new Grid({
 *     features : {
 *         fillHandle : true
 *     }
 * });
 * ```
 *
 * {@note}This feature will not work properly when Store uses {@link Core.data.Store#config-lazyLoad}{/@note}
 * @extends Core/mixin/InstancePlugin
 * @classtype fillHandle
 * @feature
 */
export default class FillHandle extends InstancePlugin.mixin(Delayable) {
    static $name = 'FillHandle';
    // region Events
    /**
     * Fired before dragging of the FillHandle starts, return `false` to prevent the drag operation.
     * @preventable
     * @event beforeFillHandleDragStart
     * @param {Grid.util.GridLocation} cell Information about the column / record
     * @param {MouseEvent} domEvent The raw DOM event
     * @on-owner
     */
    /**
     * Fired when dragging of the FillHandle starts.
     * @preventable
     * @event fillHandleDragStart
     * @param {Grid.util.GridLocation} cell Information about the column / record
     * @param {MouseEvent} domEvent The raw DOM event
     * @on-owner
     */
    /**
     * Fired while dragging the FillHandle.
     * @event fillHandleDrag
     * @param {Grid.util.GridLocation} from The from cell
     * @param {Grid.util.GridLocation} to The to cell
     * @param {MouseEvent} domEvent The raw DOM event
     * @on-owner
     */
    /**
     * Fired before the FillHandle dragging is finalized and values are applied to cells, return `false` to prevent the
     * drag operation from applying data changes.
     * @preventable
     * @async
     * @event fillHandleBeforeDragFinalize
     * @param {Grid.util.GridLocation} from The from cell
     * @param {Grid.util.GridLocation} to The to cell
     * @param {MouseEvent} domEvent The raw DOM event
     * @on-owner
     */
    /**
     * Fired after a FillHandle drag operation.
     * @event fillHandleDragEnd
     * @param {Grid.util.GridLocation} from The from cell
     * @param {Grid.util.GridLocation} to The to cell
     * @param {MouseEvent} domEvent The raw DOM event
     * @on-owner
     */
    /**
     * Fired when a FillHandle drag operation is aborted.
     * @event fillHandleDragAbort
     * @on-owner
     */
    // endregion
    static configurable = {
        /**
         * Implement this function to be able to customize the value that cells will be filled with.
         * Return `undefined` to use default calculations.
         *
         * ```javascript
         * new Grid({
         *    features : {
         *        fillHandle : {
         *           calculateFillValue({cell, column, range, record}) {
         *              if(column.field === 'number') {
         *                 return range.reduce(
         *                    (sum, location) => sum + location.record[location.column.field]
         *                 );
         *              }
         *           }
         *        }
         *    }
         * });
         * ```
         *
         * @param {Object} data Object containing information about current cell and fill value
         * @param {Grid.util.GridLocation} data.cell Current cell data
         * @param {Grid.column.Column} data.column Current cell column
         * @param {Grid.util.GridLocation[]} data.range Range from where to calculate values
         * @param {Core.data.Model} data.record Current cell record
         * @returns {String|Number|Date} Value to fill current cell
         * @config {Function}
         */
        calculateFillValue : null,
        /**
         * Set to `true` to enable the fill range to crop the original selected range. This clears the cells which were
         * a part of the original selected range, but are no longer a part of the smaller range.
         * @config {Boolean}
         */
        allowCropping : false
    };
    // Plugin configuration. This plugin chains/overrides some functions in Grid.
    static get pluginConfig() {
        return {
            before : ['onStoreRemove'],
            chain  : [
                'afterSelectionChange',
                'onContentChange',
                'fixElementHeights',
                'syncFlexedSubCols',
                'beforeSubGridInternalResize'
            ],
            override : ['getCellDataFromEvent']
        };
    }
    afterConstruct() {
        super.afterConstruct();
        const { client } = this;
        if (!client.selectionMode.cell) {
            this.disabled = true;
        }
        // Remove fill elements while cell editing
        client.ion({
            startCellEdit  : 'onStartCellEdit',
            finishCellEdit : 'onFinishCellEdit',
            cancelCellEdit : 'onFinishCellEdit',
            thisObj        : this
        });
        this._fillListeners = {};
    }
    delayable = {
        handleSelection : 'raf'
    };
    onContentChange() {
        this.handleSelection(true);
    }
    fixElementHeights() {
        this.handleSelection();
    }
    // This function is called if a subgrid changes width
    syncFlexedSubCols() {
        this.handleSelection();
    }
    // FillHandle elements needs to be removed while resize is calculating overflow
    beforeSubGridInternalResize() {
        this.removeElements();
    }
    onStoreRemove(event) {
        // Selection will not act upon remove if it is a collapse, but FillHandle needs to recalculate
        if (event.isCollapse) {
            this.handleSelection(true);
        }
    }
    onStartCellEdit() {
        this.removeElements(false);
    }
    onFinishCellEdit() {
        this.handleSelection();
    }
    getCellDataFromEvent(event, includeSingleAxisMatch) {
        if (includeSingleAxisMatch) {
            includeSingleAxisMatch = !event.target.classList.contains('b-fill-handle');
        }
        return this.overridden.getCellDataFromEvent(event, includeSingleAxisMatch);
    }
    get currentRangeForEvents() {
        if (this.currentRange) {
            let { from, to, negative } = this.currentRange;
            // negative means down -> up or left -> right
            if (negative) {
                [from, to] = [to, from];
            }
            return { from, to };
        }
    }
    // region Pattern recognition
    findPatternsIn2dRange(range, horizontal, negative) {
        const values = {};
        // Converts a cellselector range to values per column or row
        for (const cell of range) {
            const id  = horizontal ? cell.id : cell.columnId;
            let value = cell.record.getValue(cell.column.field);
            // If a number string, convert to number
            if (value && typeof value === 'string' && !isNaN(value)) {
                value = parseFloat(value);
            }
            if (!values[id]) {
                values[id] = [];
            }
            values[id].push(value);
        }
        // Find patterns for each column or row in range
        for (const rowOrCol in values) {
            values[rowOrCol].pattern = this.findPatternsIn1dRange(values[rowOrCol], negative);
        }
        return values;
    }
    findPatternsIn1dRange(range, negative) {
        const
            lastValue = range[negative ? 0 : (range.length - 1)],
            pattern   = {
                next : () => lastValue,
                lastValue
            };
        // If all values in same column/row is either number or date
        if (range.every(val => typeof val === 'number') || range.every(val => val instanceof Date)) {
            const diffs = range.map((val, ix) => val - range[ix - 1]);
            diffs.shift(); // Removes initial NaN
            // Found a repeating pattern
            if (new Set(diffs).size === 1) {
                pattern.increaseBy = diffs[0] * (negative ? -1 : 1);
                pattern.next = () => {
                    if (pattern.lastValue instanceof Date) {
                        pattern.lastValue = new Date(pattern.lastValue.getTime() + pattern.increaseBy);
                    }
                    else {
                        pattern.lastValue += pattern.increaseBy;
                    }
                    return pattern.lastValue;
                };
            }
        }
        // Else it's treated as a string value
        else if (range.length > 1) {
            pattern.stringPattern = [...range];
            pattern.next          = () => {
                if (pattern.currentIndex === undefined) {
                    pattern.currentIndex = 0;
                }
                else {
                    pattern.currentIndex += 1;
                    if (pattern.currentIndex >= pattern.stringPattern.length) {
                        pattern.currentIndex = 0;
                    }
                }
                return pattern.stringPattern[pattern.currentIndex];
            };
        }
        return pattern;
    }
    // endregion
    afterSelectionChange() {
        const me = this;
        if (me.client.readOnly || me.disabled) {
            me.removeElements();
            return;
        }
        // If selection isn't finished, wait for mouse up and then add fill elements
        if (GlobalEvents.isMouseDown()) {
            me.client.delayUntilMouseUp(() => me.handleSelection(true));
            // Remove prev elements immediately in this case
            me.removeElements();
        }
        // Otherwise, add fill elements immediately
        else {
            me.handleSelection(true);
        }
    }
    /**
     * Checks selection and sees to it that fill handle and border is drawn.
     * Runs on next animation frame
     * @internal
     */
    handleSelection(clearCache = false) {
        if (this.enabled && !this._isExtending) {
            if (clearCache) {
                this._cachedSelectedRange = null;
            }
            const range = this.rangeSelection;
            if (range) {
                this.drawFillHandleAndBorder(range[0], range[range.length - 1]);
            }
            else {
                this.removeElements();
            }
        }
    }
    // region Mouse events
    // On fillHandle mouse down only
    onMouseDown(domEvent) {
        const { client } = this;
        if (this.enabled && !client.readOnly && client.trigger('beforeFillHandleDragStart', {
            cell : client.focusedCell,
            domEvent
        }) !== false) {
            this._fillListeners.mouseMoveOrUp = EventHelper.on({
                element    : this.rootElement,
                keydown    : 'onKeyDown',
                mouseenter : {
                    handler  : 'onMouseOver',
                    delegate : '.b-grid-cell',
                    element  : client.selectionDragMouseEventListenerElement
                },
                capture : true,
                mouseup : 'onMouseUp',
                thisObj : this
            });
            domEvent.preventDefault();
            domEvent.stopImmediatePropagation();
            domEvent.handled = true;
            client.trigger('fillHandleDragStart', { cell : client.focusedCell, domEvent });
        }
    }
    // Responsible for doing the filling
    async onMouseUp(domEvent) {
        const result = await this.client.trigger('fillHandleBeforeDragFinalize', { ...this.currentRangeForEvents, domEvent }) !== false;
        this.finalize(result, domEvent);
    }
    finalize(commit, domEvent = true) {
        const
            me              = this,
            {
                client,
                currentRange,
                currentRangeForEvents,
                _isCropping
            }               = me,
            range           = me.rangeSelection,
            selectionChange = range && currentRange && client.internalSelectRange(currentRange.from, currentRange.to),
            selectedCells   = selectionChange?.selectedCells || [],
            // For extending : Only modify cells that are not a part of original range
            // For cropping  : Only clear cells that are not a part of new selection
            extensionCells  = _isCropping ? me.croppingCells
                : selectedCells.filter(cell => !range.some(sel => sel.equals(cell, true)));
        me._isCropping = null; // Removing flag in case we bail out early
        if (me._isExtending) {
            client.disableScrollingCloseToEdges(client.items);
            me._isExtending = null;
        }
        // If no extension, do nothing
        if (!extensionCells?.length || !commit) {
            me.currentRange = null;
            me.handleSelection();
            client.trigger('fillHandleDragAbort', { cell : client.focusedCell });
            return;
        }
        client.suspendRefresh();
        // If trimming (inverted extension), clear cells that where previously selected and not a part of new selection
        if (_isCropping) {
            extensionCells.forEach(cell => cell.record.set(cell.column.field, null, false, false, false, true));
        }
        // Extending cell values depending on pattern
        else {
            const
                [firstCell] = extensionCells,
                // If extensioncells has a record that is included in original selection, then we are dragging horizontally
                horizontal  = range.some(sel => sel.record === firstCell.record),
                // negative in this aspect, means dragging either upwards or to the left depending on horizontal or vertical
                negative    = horizontal
                    ? firstCell.columnIndex < range[0].columnIndex
                    : firstCell.rowIndex < range[0].rowIndex,
                patterns    = me.findPatternsIn2dRange(range, horizontal, negative),
                changeMap   = new Map();
            if (negative) {
                extensionCells.reverse();
            }
            for (const cell of extensionCells) {
                const { column, record } = cell;
                if (!record.readOnly && !column.readOnly && column.canFillValue({ range, record, cell }) && record.isEditable?.(column.field) !== false) {
                    let value   = me.calculateFillValue?.({ range, column, record, cell }),
                        changed = changeMap.get(record);
                    if (!changed) {
                        changed = {};
                        changeMap.set(record, changed);
                    }
                    if (value === undefined) {
                        const pattern = patterns[horizontal ? cell.id : cell.columnId].pattern;
                        value         = pattern.next();
                    }
                    changed[column.field] = column.calculateFillValue?.({ value, record, range }) || value;
                }
            }
            for (const [record, changes] of changeMap) {
                record.set(changes, null, null, null, null, true);
            }
        }
        client.resumeRefresh(true);
        // Selects the extended area
        client.performSelection(selectionChange);
        me.currentRange = null;
        me.handleSelection();
        client.trigger('fillHandleDragEnd', { ...currentRangeForEvents, domEvent });
    }
    // The fill border and handle should refresh on mouse move
    onMouseOver(domEvent) {
        const
            me           = this,
            {
                client,
                rangeSelection
            }            = me,
            first        = rangeSelection[0],
            last         = rangeSelection[rangeSelection.length - 1],
            cellData     = client.getCellDataFromEvent(domEvent, true);
        let cellSelector = cellData && client.normalizeCellContext(cellData.cellSelector);
        if (cellSelector?._column?.region === first._column.region) {
            const
                equalOrSmaller = rangeSelection.some(cs => cs.equals(cellSelector, true));
            let negative;
            if (!me._isExtending) {
                client.enableScrollingCloseToEdges(client.items);
            }
            // If were smaller, were cropping (if it's allowed)
            me._isCropping = equalOrSmaller && me.allowCropping &&
                    (cellSelector.rowIndex < last.rowIndex || cellSelector.columnIndex < last.columnIndex);
            if (!equalOrSmaller) {
                // If cellSelector is on a row in range, endSelector should be current column but end/first row
                if (cellSelector.rowIndex >= first.rowIndex && cellSelector.rowIndex <= last.rowIndex) {
                    negative     = first.columnIndex > cellSelector.columnIndex;
                    cellSelector = new GridLocation({
                        grid   : client,
                        record : negative ? first.record : last.record,
                        column : cellSelector.column
                    });
                }
                // Else endSelector should be current row but end/first column
                else {
                    negative     = first.rowIndex > cellSelector.rowIndex;
                    cellSelector = new GridLocation({
                        grid   : client,
                        record : cellSelector.record,
                        column : negative ? first.column : last.column
                    });
                }
            }
            // negative means that current mouse over cell is above or to the left
            const
                // If negative, draw from calculated mouse over cell
                // otherwise, draw from top-left selection cell
                from = negative ? cellSelector : first,
                // If negative or were inside selection (but not cropping), draw to bottom-right selection cell
                // otherwise, draw to calculated mouse over cell
                to   = negative || (equalOrSmaller && !me._isCropping) ? last : cellSelector;
            me.currentRange = { from, to, negative };
            // This flag is true even if were trimming
            me._isExtending = true;
            me.drawFillHandleAndBorder(from, to, true);
            client.trigger('fillHandleDrag', { ...me.currentRangeForEvents, domEvent });
        }
    }
    // endregion
    // region Creating, updating and removing fillhandle and fillborder
    drawFillHandleAndBorder(from, to, keepListeners = false) {
        const
            me       = this,
            {
                client,
                _fillListeners
            }        = me,
            regionEl = client.subGrids[from.column.region].element,
            fromRect = Rectangle.from(from.cell || from.column.element, regionEl),
            toRect   = Rectangle.from(to.cell || to.column.element, regionEl),
            { y }    = client.getRecordCoords(from.record, true),
            bottom   = client.getRecordCoords(to.record, true).bottom - 1;
        let {
            borderElement,
            handleElement
        } = me;
        me.removeElements(keepListeners);
        if (!borderElement) {
            me.borderElement = borderElement = DomHelper.createElement({
                className : 'b-fill-selection-border'
            });
            me.handleElement = handleElement = DomHelper.createElement({
                className : 'b-fill-handle'
            });
        }
        if (client.rtl) {
            const
                regionRect = Rectangle.from(client.subGrids[from.column.region].element),
                x          = regionRect.width - fromRect.right,
                width      = toRect.left > fromRect.left ? toRect.right - fromRect.left : fromRect.right - toRect.left;
            DomHelper.setRect(borderElement, { x, y, width, height : bottom - y });
            DomHelper.setTopInsetInlineStart(handleElement, bottom, x + width);
            // We're drawing at the edge of the subgrid (-1 to account for subpixel rendering)
            // Set class to adjust the fill handle position and width if drawn to the right on the end of the subgrid
            handleElement.classList.toggle('b-fill-handle-left-edge', toRect.left <= 0);
        }
        else {
            DomHelper.setRect(borderElement, { y, x : fromRect.x, width : (toRect.right - fromRect.x), height : bottom - y });
            DomHelper.setTopInsetInlineStart(handleElement, bottom, toRect.right);
            // We're drawing at the edge of the subgrid (-1 to account for subpixel rendering)
            // Set class to adjust the fill handle position and width if drawn to the right on the end of the subgrid
            handleElement.classList.toggle('b-fill-handle-right-edge', toRect.right >= regionEl.scrollWidth - 1);
        }
        regionEl.appendChild(borderElement);
        regionEl.appendChild(handleElement);
        // Remove all previously cropping cls
        me.toggleCroppingCls(false);
        me.croppingCells = null;
        // If we are cropping, we should add cls class to the cells that will be "shrunk"
        if (me._isCropping && me.rangeSelection?.length) {
            const newCells = client.getRange(from, to);
            me.croppingCells = me.rangeSelection.filter(sel => !newCells.some(cell => cell.equals(sel, true)));
            me.toggleCroppingCls();
        }
        if (!_fillListeners.handleClick) {
            _fillListeners.handleClick = EventHelper.on({
                element   : client.rootElement,
                delegate  : '.b-fill-handle',
                mousedown : 'onMouseDown',
                thisObj   : me
            });
        }
        me.hasFillElements = true;
    }
    toggleCroppingCls(add = true) {
        this.croppingCells?.forEach(sel => this.client.getCell(sel)?.classList.toggle('b-indicate-crop', add));
    }
    removeElements(keepListeners = false) {
        const me = this;
        me.handleElement?.remove();
        me.borderElement?.remove();
        if (!keepListeners) {
            me.removeListeners();
        }
        me.hasFillElements = false;
    }
    // Detach listeners
    removeListeners() {
        const me = this;
        for (const listener in me._fillListeners) {
            me._fillListeners[listener]();
        }
        me._fillListeners = {};
    }
    // endregion
    // Gets current selection range. Only allows for single range or single cell.
    get rangeSelection() {
        if (!this._cachedSelectedRange) {
            this._cachedSelectedRange = this.currentSelectedRange;
        }
        return this._cachedSelectedRange;
    }
    // Calculate cell ranges
    get currentSelectedRange() {
        const
            { client }        = this,
            { store }         = client;
        let { selectedCells } = client;
        if (!client._selectedRows.length && selectedCells.length &&
            selectedCells.every(({ record }) => store.indexOf(record) >= 0)
        ) {
            // We allow only one selected range and nothing else selected
            if (selectedCells.length > 1) {
                selectedCells = selectedCells.sort((a, b) => a.rowIndex - b.rowIndex || a.columnIndex - b.columnIndex);
                const
                    [first]   = selectedCells,
                    [last]    = selectedCells.slice(-1),
                    startRow  = first.rowIndex,
                    startCol  = first.columnIndex,
                    region    = first.region,
                    endRow    = last.rowIndex,
                    endCol    = last.columnIndex,
                    cellCount = (endCol - startCol + 1) * (endRow - startRow + 1);
                // If cell count is correct, and all cells is in same region and between the start cells row/col and the end
                // cells row/col, the selection is a single range
                if (selectedCells.length === cellCount &&
                    selectedCells.every(cell => cell.region === region &&
                        cell.rowIndex >= startRow && cell.rowIndex <= endRow &&
                        cell.columnIndex >= startCol && cell.columnIndex <= endCol)
                ) {
                    return selectedCells;
                }
            }
            else {
                return selectedCells;
            }
        }
    }
    onKeyDown(event) {
        if (event.key === 'Escape') {
            this.abort();
        }
    }
    /**
     * Aborts an ongoing FillHandle drag operation
     */
    abort() {
        this.finalize(false);
        this.reset();
    }
    reset() {
        this._isExtending = this._isCropping = this.currentRange = this.croppingCells = null;
    }
    doDisable(disable) {
        this.removeElements();
        this.reset();
        super.doDisable(disable);
    }
}
FillHandle._$name = 'FillHandle'; GridFeatureManager.registerFeature(FillHandle);
