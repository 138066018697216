import DataField from './DataField.js';
import DateHelper from '../../helper/DateHelper.js';
/**
 * @module Core/data/field/DateDataField
 */
/**
 * This field class handles field of type `Date`.
 *
 * ```javascript
 * class Person extends Model {
 *     static get fields() {
 *         return [
 *             'name',
 *             { name : 'birthday', type : 'date', format : 'YYYY-MM-DD' },
 *             { name : 'age', readOnly : true }
 *         ];
 *     }
 * }
 * ```
 *
 * When a field is declared as a `'date'`, non-null values are promoted to `Date` type. This is frequently needed due
 * to how date types are serialized to JSON strings.
 *
 * Date fields can have a special `defaultValue` of `'now'` which will convert to the current date/time.
 *
 * @extends Core/data/field/DataField
 * @classtype date
 * @datafield
 */
export default class DateDataField extends DataField {
    static get $name() {
        return 'DateDataField';
    }
    static get type() {
        return 'date';
    }
    static get prototypeProperties() {
        return {
            /**
             * The format of the date field.
             *
             * See {@link Core.helper.DateHelper} for details.
             * @config {String} format
             * @default DateHelper.defaultFormat
             */
            format : null
        };
    }
    convert(value) {
        if (value == null) {
            if (!this.nullable) {
                value = this.nullValue;
            }
        }
        else if (value === 'now') {
            value = new Date();
        }
        else if (!(value instanceof Date)) {
            const rawValue = value;
            // Use configured format, if null/undefined use DateHelpers default format
            value = DateHelper.parse(value, this.format || DateHelper.defaultParseFormat);
            // if parsing has failed, we would like to return `undefined` to indicate the "absence" of data
            // instead of `null` (presence of "empty" data)
            if (!value || isNaN(value)) {
                value = undefined;
                if (rawValue !== '') {
                    console.warn(`Date parsing failed for "${this.name}" field, input: ${JSON.stringify(rawValue)}. ${this.format ? `Expected format: ${this.format}.` : `You should configure your Model field with a "format" to specify how dates should be parsed. See docs for DateDataField here: https://bryntum.com/products/scheduler/docs/api/Core/data/field/DateDataField, and see https://bryntum.com/products/scheduler/docs/api/Core/helper/DateHelper for the different formats.`}"`);
                }
            }
        }
        return value;
    }
    serialize(value) {
        if (value instanceof Date) {
            // Use configured format or DateHelpers default one
            value = DateHelper.format(value, this.format || DateHelper.defaultFormat);
        }
        return value;
    }
    printValue(value) {
        return DateHelper.format(value, this.format || DateHelper.defaultFormat);
    }
}
DateDataField.initClass();
DateDataField._$name = 'DateDataField';