import DomHelper from '../../Core/helper/DomHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import Popup from '../../Core/widget/Popup.js';
import Tooltip from '../../Core/widget/Tooltip.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import CollectionFilter from '../../Core/util/CollectionFilter.js';
import '../../Core/widget/NumberField.js';
import '../../Core/widget/Combo.js';
import '../../Core/widget/DateField.js';
import '../../Core/widget/TimeField.js';
import GridFeatureManager from './GridFeatureManager.js';
import '../widget/GridFieldFilterPickerGroup.js';
import FieldFilterPicker, { filterableFieldDataTypes } from '../../Core/widget/FieldFilterPicker.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import DomClassList from '../../Core/helper/util/DomClassList.js';
import ArrayHelper from '../../Core/helper/ArrayHelper.js';
/**
 * @module Grid/feature/Filter
 */
// Map column.filterType, column.type, or dataField.type -> CollectionFilter.type
export const fieldTypeMap = {
    date     : 'date',
    int      : 'number',
    integer  : 'number',
    number   : 'number',
    string   : 'string',
    duration : 'duration',
    time     : 'time',
    auto     : 'auto'
};
const
    // Allow filters with supported DataField types, plus 'relation'
    allowedFilterTypes = {
        ...filterableFieldDataTypes,
        relation : true
    },
    menuItemsWithSeparators = {
        filterDateIsToday     : true,
        filterDateIsThisWeek  : true,
        filterDateIsThisMonth : true,
        filterDateIsThisYear  : true
    };
/**
 * Feature that allows filtering of the grid by settings filters on columns. The actual filtering is done by the store.
 * For info on programmatically handling filters, see {@link Core.data.mixin.StoreFilter}.
 *
 * {@inlineexample Grid/feature/Filter.js}
 *
 * ```javascript
 * // Filtering turned on but no default filter
 * const grid = new Grid({
 *   features : {
 *     filter : true
 *   }
 * });
 *
 * // Using default filter
 * const grid = new Grid({
 *   features : {
 *     filter : { property : 'city', value : 'Gavle' }
 *   }
 * });
 * ```
 *
 * A column can supply a custom filtering function as its {@link Grid.column.Column#config-filterable} config. When
 * filtering by that column using the UI that function will be used to determine which records to include. See
 * {@link Grid.column.Column#config-filterable Column#filterable} for more information.
 *
 * ```javascript
 * // Custom filtering function for a column
 * const grid = new Grid({
 *    features : {
 *        filter : true
 *    },
 *
 *    columns: [
 *        {
 *          field      : 'age',
 *          text       : 'Age',
 *          type       : 'number',
 *          // Custom filtering function that checks "greater than" no matter
 *          // which field user filled in :)
 *          filterable : ({ record, value, operator }) => record.age > value
 *        }
 *    ]
 * });
 * ```
 *
 * If this feature is configured with `prioritizeColumns : true`, those functions will also be used when filtering
 * programmatically:
 *
 * ```javascript
 * const grid = new Grid({
 *    features : {
 *        filter : {
 *            prioritizeColumns : true
 *        }
 *    },
 *
 *    columns: [
 *        {
 *          field      : 'age',
 *          text       : 'Age',
 *          type       : 'number',
 *          filterable : ({ record, value, operator }) => record.age > value
 *        }
 *    ]
 * });
 *
 * // Because of the prioritizeColumns config above, any custom filterable function
 * // on a column will be used when programmatically filtering by that columns field
 * grid.store.filter({
 *     property : 'age',
 *     value    : 41
 * });
 * ```
 *
 * This feature is <strong>disabled</strong> by default.
 *
 * ## Keyboard shortcuts
 * This feature has the following default keyboard shortcuts:
 *
 * | Keys   | Action                  | Action description                                                     |
 * |--------|-------------------------|------------------------------------------------------------------------|
 * | F      | showFilterEditorByKey   | When the column header is focused, this shows the filter input field   |
 *
 * <div class="note">Please note that <code>Ctrl</code> is the equivalent to <code>Command</code> and <code>Alt</code>
 * is the equivalent to <code>Option</code> for Mac users</div>
 *
 * For more information on how to customize keyboard shortcuts, please see
 * [our guide](#Grid/guides/customization/keymap.md).
 *
 * ## Menu items
 *
 * The following items are populated under the Filter submenu (in the cell and column header context menus) when the Filter feature is active.
 *
 * | Item reference                | Text                 | Weight | Enabled by default | Description                                                                                                                                           |
 * |-------------------------------|----------------------|--------|--------------------|-------------------------------------------------------------------------------------------------------------------------------------------------------|
 * | `filterAuto*`                 | Contains             | 310    | true               | Filters records in the store to those where the column field contains the selected cell value                                                         |
 * | `filterBooleanIsTrue`         | True                 | 310    | true               | Filters records in the store to those where the column field is true                                                                                  |
 * | `filterBooleanIsFalse`        | False                | 320    | true               | Filters records in the store to those where the column field is false                                                                                 |
 * | `filterDateEmpty`             | Empty                | 310    | true               | Filters records in the store to those where the column field empty                                                                                    |
 * | `filterDateNotEmpty`          | Not empty            | 320    | true               | Filters records in the store to those where the column field not empty                                                                                |
 * | `filterDate=`                 | Equals               | 330    | true               | Filters records in the store to those where the column field is equal to the selected cell value                                                      |
 * | `filterDate!=`                | Does not equal       | 340    | true               | Filters records in the store to those where the column field is not equal to the selected cell value                                                  |
 * | `filterDate<`                 | Less than            | 350    | true               | Filters records in the store to those where the column field is less than the selected cell value                                                     |
 * | `filterDate>`                 | Greater than         | 360    | true               | Filters records in the store to those where the column field is greater than the selected cell value                                                  |
 * | `filterDateIsToday`           | Today                | 370    | true               | Filters records in the store to those where the column field is today                                                                                 |
 * | `filterDateIsTomorrow`        | Tomorrow             | 380    | true               | Filters records in the store to those where the column field is tomorrow                                                                              |
 * | `filterDateIsYesterday`       | Yesterday            | 390    | true               | Filters records in the store to those where the column field is yesterday                                                                             |
 * | `filterDateIsThisWeek`        | This week            | 400    | true               | Filters records in the store to those where the column field is this week                                                                             |
 * | `filterDateIsNextWeek`        | Next week            | 410    | true               | Filters records in the store to those where the column field is next week                                                                             |
 * | `filterDateIsLastWeek`        | Last week            | 420    | true               | Filters records in the store to those where the column field is last week                                                                             |
 * | `filterDateIsThisMonth`       | This month           | 430    | true               | Filters records in the store to those where the column field is this month                                                                            |
 * | `filterDateIsNextMonth`       | Next month           | 440    | true               | Filters records in the store to those where the column field is next month                                                                            |
 * | `filterDateIsLastMonth`       | Last month           | 450    | true               | Filters records in the store to those where the column field is last month                                                                            |
 * | `filterDateIsThisYear`        | This year            | 460    | true               | Filters records in the store to those where the column field is this year                                                                             |
 * | `filterDateIsNextYear`        | Next year            | 470    | true               | Filters records in the store to those where the column field is next year                                                                             |
 * | `filterDateIsLastYear`        | Last year            | 480    | true               | Filters records in the store to those where the column field is last year                                                                             |
 * | `filterDateIsYearToDate`      | Year to date         | 490    | true               | Filters records in the store to those where the column field is within the year to date                                                               |
 * | `filterDurationEmpty`         | Empty                | 310    | true               | Filters records in the store to those where the column field is empty                                                                                 |
 * | `filterDurationNotEmpty`      | Not empty            | 320    | true               | Filters records in the store to those where the column field is not empty                                                                             |
 * | `filterDuration=`             | Equals               | 330    | true               | Filters records in the store to those where the column field is equal to the selected cell value                                                      |
 * | `filterDuration!=`            | Does not equal       | 340    | true               | Filters records in the store to those where the column field is not equal to the selected cell value                                                  |
 * | `filterDuration>`             | Greater than         | 350    | true               | Filters records in the store to those where the column field is greater than the selected cell value                                                  |
 * | `filterDuration<`             | Less than            | 360    | true               | Filters records in the store to those where the column field is less than the selected cell value                                                     |
 * | `filterDuration>=`            | Greater or equals    | 370    | true               | Filters records in the store to those where the column field is greater than or equal to the selected cell value                                      |
 * | `filterDuration<=`            | Less or equals       | 380    | true               | Filters records in the store to those where the column field is less than or equal to the selected cell value                                         |
 * | `filterNumberEmpty`           | Empty                | 310    | true               | Filters records in the store to those where the column field is empty                                                                                 |
 * | `filterNumberNotEmpty`        | Not empty            | 320    | true               | Filters records in the store to those where the column field is not empty                                                                             |
 * | `filterNumber=`               | Equals               | 330    | true               | Filters records in the store to those where the column field is equal to the selected cell value                                                      |
 * | `filterNumber!=`              | Does not equal       | 340    | true               | Filters records in the store to those where the column field is not equal to the selected cell value                                                  |
 * | `filterNumber>`               | Greater than         | 350    | true               | Filters records in the store to those where the column field is greater than the selected cell value                                                  |
 * | `filterNumber<`               | Less than            | 360    | true               | Filters records in the store to those where the column field is less than the selected cell value                                                     |
 * | `filterNumber>=`              | Greater or equals    | 370    | true               | Filters records in the store to those where the column field is greater than or equal to the selected cell value                                      |
 * | `filterNumber<=`              | Less or equals       | 380    | true               | Filters records in the store to those where the column field is less than or equal to the selected cell value                                         |
 * | `filterRelationEmpty`         | Empty                | 310    | true               | Filters records in the store to those where the column field is empty                                                                                 |
 * | `filterRelationNotEmpty`      | Not empty            | 320    | true               | Filters records in the store to those where the column field is not empty                                                                             |
 * | `filterRelation=`             | Equals               | 330    | true               | Filters records in the store to those where the column field is equal to the selected cell value                                                      |
 * | `filterRelation!=`            | Does not equal       | 340    | true               | Filters records in the store to those where the column field is not equal to the selected cell value                                                  |
 * | `filterStringEmpty`           | Empty                | 310    | true               | Filters records in the store to those where the column field is empty                                                                                 |
 * | `filterStringNotEmpty`        | Not empty            | 320    | true               | Filters records in the store to those where the column field is not empty                                                                             |
 * | `filterString=`               | Equals               | 330    | true               | Filters records in the store to those where the column field is equal to the selected cell value                                                      |
 * | `filterString!=`              | Does not equal       | 340    | true               | Filters records in the store to those where the column field is not equal to the selected cell value                                                  |
 * | `filterStringIncludes`        | Contains             | 350    | true               | Filters records in the store to those where the column field contains the selected cell value                                                         |
 * | `filterStringDoesNotInclude`  | Does not contain     | 360    | true               | Filters records in the store to those where the column field does not contain the selected cell value                                                 |
 * | `filterStringStartsWith`      | Starts with          | 370    | true               | Filters records in the store to those where the column field starts with to the selected cell value                                                   |
 * | `filterStringEndsWith`        | Ends with            | 380    | true               | Filters records in the store to those where the column field ends with to the selected cell value                                                     |
 * | `filterEdit`                  | Edit filter          | 500    | false              | Opens a popup to edit the current filter. Only shown when current column is filtered.                            |
 * | `filterRemove`                | Remove filter        | 510    | false              | Stops filtering by selected column field. Only shown when current column is filtered.                          |
 * | `filterDisable`               | Disable filter       | 520    | false              | Temporarily stops filtering by selected column field. Only shown when current column is filtered.             |
 *
 * ## Legacy UI mode
 *
 * To use the more limited legacy UI instead, configure {@link #config-legacyMode} to `true`.
 *
 * You can supply a field config to use for the filtering field displayed for string type columns (legacy mode only):
 *
 * ```javascript
 * // For string-type columns you can also replace the filter UI with a custom field:
 * columns: [
 *     {
 *         field : 'city',
 *         // Filtering for a value out of a list of values
 *         filterable: {
 *             filterField : {
 *                 type     : 'combo',
 *                 operator : 'isIncludedIn',
 *                 items    : [
 *                     'Paris',
 *                     'Dubai',
 *                     'Montreal',
 *                     'London',
 *                     'New York'
 *                 ]
 *             }
 *         }
 *     }
 * ]
 * ```
 *
 * You can also change default fields, for example this will use {@link Core.widget.DateTimeField} in filter popup
 * (legacy mode only):
 *
 * ```javascript
 * columns : [
 *     {
 *         type       : 'date',
 *         field      : 'start',
 *         filterable : {
 *             filterField : {
 *                 type : 'datetime'
 *             }
 *         }
 *     }
 * ]
 * ```
 *
 * @extends Core/mixin/InstancePlugin
 * @demo Grid/filtering
 * @classtype filter
 * @feature
 */
export default class Filter extends InstancePlugin {
    //region Init
    static get $name() {
        return 'Filter';
    }
    static get configurable() {
        return {
            /**
             * Use custom filtering functions defined on columns also when programmatically filtering by the columns
             * field.
             *
             * ```javascript
             * const grid = new Grid({
             *     columns : [
             *         {
             *             field : 'age',
             *             text : 'Age',
             *             filterable({ record, value }) {
             *               // Custom filtering, return true/false
             *             }
             *         }
             *     ],
             *
             *     features : {
             *         filter : {
             *             prioritizeColumns : true // <--
             *         }
             *     }
             * });
             *
             * // Because of the prioritizeColumns config above, any custom
             * // filterable function on a column will be used when
             * // programmatically filtering by that columns field
             * grid.store.filter({
             *     property : 'age',
             *     value    : 30
             * });
             * ```
             *
             * @config {Boolean}
             * @default
             * @category Common
             */
            prioritizeColumns : false,
            /**
             * See {@link #keyboard-shortcuts Keyboard shortcuts} for details
             * @config {Object<String,String>}
             */
            keyMap : {
                f : 'showFilterEditorByKey'
            },
            /**
             * Set to `true` to enable the more limited legacy UI mode.
             *
             * @config {Boolean}
             * @default
             * @category Common
             */
            legacyMode : false,
            /**
             * Optional configuration to use when configuring the {@link Grid.widget.GridFieldFilterPickerGroup} shown in the
             * column header popup, when not in legacy mode.
             *
             * @config {GridFieldFilterPickerGroupConfig}
             * @category Common
             */
            pickerConfig : null,
            /**
             * When true, close the popup when the last filter shown in the popup is removed using the remove button. Not
             * applicable in legacy mode.
             *
             * @config {Boolean}
             * @default
             * @category Common
             */
            closeEmptyPopup : false,
            /**
             * The value against which to compare the {@link #config-property} of candidate objects.
             * @config {*}
             */
            value : null,
            /**
             * The name of a property of candidate objects which yields the value to compare.
             * @config {String}
             */
            property : null,
            /**
             * The operator to use when comparing a candidate object's {@link #config-property}.
             * @config {CollectionCompareOperator}
             */
            operator : null,
            /**
             * Which operator to pre-fill for the blank filter shown by default in the filter pop-up, keyed by
             * the column field's data type. See {@link Core.util.CollectionFilter#typedef-CollectionCompareOperator}
             * for available operators.
             *
             * Default value:
             *
             * ```javascript
             * {
             *     date     : '=',
             *     number   : '=',
             *     string   : 'includes',
             *     duration : '=',
             *     relation : null,
             *     auto     : '*'
             * }
             * ```
             *
             * @config {Object}
             */
            defaultOperators : {
                date     : '=',
                number   : '=',
                string   : 'includes',
                duration : '=',
                relation : null,
                auto     : '*'
            },
            /**
             * This makes it possible to generally limit the allowed operators which populates the filtering sub-menus.
             * See {@link Core.util.CollectionFilter#typedef-CollectionCompareOperator} for available operators.
             *
             * ```javascript
             * const grid = new Grid({
             *     features : {
             *         filter : {
             *             allowedOperators : ['*', '=', '<', '>']
             *         }
             *     }
             * });
             * ```
             *
             * @config {Object}
             */
            allowedOperators : null,
            /**
             * Whether the feature is enabled on all columns by default. A column's `filterable.filter`
             * configuration overrides this setting.
             * @prp {Boolean}
             * @category Common
             * @default
             */
            defaultEnabled : true
        };
    }
    construct(client, config) {
        const me = this;
        me.closeFilterEditor = me.closeFilterEditor.bind(me);
        me.inferredTypes = {};
        super.construct(client, config);
        me.bindStore(me.store);
        if (config && typeof config === 'object') {
            const clone = ObjectHelper.clone(config);
            // Remove non-CollectionFilter config properties, to use config as filter
            delete clone.prioritizeColumns;
            delete clone.legacyMode;
            delete clone.pickerConfig;
            delete clone.dateFormat;
            delete clone.closeEmptyPopup;
            delete clone.defaultOperators;
            delete clone.defaultEnabled;
            delete clone.allowedOperators;
            if (!ObjectHelper.isEmpty(clone)) {
                me.store.filter(clone, null, client.isConfiguring);
            }
        }
    }
    doDestroy() {
        this.filterTip?.destroy();
        this.filterEditorPopup?.destroy();
        super.doDestroy();
    }
    get store() {
        return this.client.$store;
    }
    bindStore() {
        this.detachListeners('store');
        this.store.ion({
            name         : 'store',
            beforeFilter : 'onStoreBeforeFilter',
            filter       : 'onStoreFilter',
            thisObj      : this
        });
        if (this.client.isPainted) {
            this.refreshHeaders(false);
        }
    }
    //endregion
    //region Plugin config
    // Plugin configuration. This plugin chains some of the functions in Grid.
    static get pluginConfig() {
        return {
            chain : ['renderHeader', 'populateCellMenu', 'populateHeaderMenu', 'onElementClick', 'bindStore']
        };
    }
    //endregion
    //region Refresh headers
    /**
     * Update headers to match stores filters. Called on store load and grid header render.
     * @param reRenderRows Also refresh rows?
     * @private
     */
    refreshHeaders(reRenderRows) {
        const
            me                = this,
            { client, store } = me,
            element           = client.headerContainer;
        if (element) {
            // remove .latest from all filters, will be applied to actual latest
            DomHelper.children(element, '.b-filter-icon.b-latest').forEach(iconElement => iconElement.classList.remove('b-latest'));
            if (!me.filterTip) {
                me.filterTip = new Tooltip({
                    forElement  : element,
                    forSelector : '.b-filter-icon',
                    getHtml({ activeTarget }) {
                        return activeTarget.dataset.filterText;
                    }
                });
            }
            if (!store.isFiltered) {
                me.filterTip.hide();
            }
            client.columns.visibleColumns.forEach(column => {
                if (me.canFilterColumn(column)) {
                    const
                        columnFilters    = store.filters.allValues.filter(({ property, disabled, internal, isNoOp }) =>
                            property === column.field &&
                            !disabled &&
                            !internal &&
                            !isNoOp),
                        isColumnFiltered = columnFilters.length > 0,
                        headerEl = column.element;
                    if (headerEl) {
                        const textEl = column.textWrapper;
                        let filterIconEl = textEl?.querySelector('.b-filter-icon'),
                            filterText;
                        if (isColumnFiltered) {
                            const bullet = '&#x2022 ';
                            filterText = `${me.L('L{filter}')}: ` +
                                (columnFilters.length > 1 ? '<br/><br/>' : '') +
                                columnFilters.map(columnFilter => {
                                    let value = columnFilter.value ?? '';
                                    const
                                        isArray = Array.isArray(value),
                                        relation = store?.modelRelations?.find(
                                            ({ foreignKey }) => foreignKey === columnFilter.property);
                                    if (columnFilter.displayValue) {
                                        value = columnFilter.displayValue;
                                    }
                                    else {
                                        if (!me.legacyMode && relation) {
                                            // Look up remote display value per filterable-field config (FieldFilterPicker.js#FieldOption)
                                            const { relatedDisplayField } = me.pickerConfig?.fields?.[columnFilter.property];
                                            if (relatedDisplayField) {
                                                const getDisplayValue = foreignId =>
                                                    relation.foreignStore.getById(foreignId)?.[relatedDisplayField];
                                                if (isArray) {
                                                    value = value.map(getDisplayValue)
                                                        .sort((a, b) => (a ?? '').localeCompare(b ?? ''));
                                                }
                                                else {
                                                    value = getDisplayValue(value);
                                                }
                                            }
                                        }
                                        else if (column.formatValue && value) {
                                            value = isArray
                                                ? value.map(val => column.formatValue(val))
                                                : column.formatValue(value);
                                        }
                                        if (isArray) {
                                            value = `[ ${value.join(', ')} ]`;
                                        }
                                    }
                                    const localizedOperator = FieldFilterPicker.localizeOperator(columnFilter.operator,
                                        me.getFilterType(column));
                                    return (columnFilters.length > 1 ? bullet : '') +
                                        (typeof columnFilter === 'string'
                                            ? columnFilter
                                            : `${localizedOperator} ${value}`);
                                }).join('<br/><br/>');
                        }
                        else {
                            filterText = me.L('L{applyFilter}');
                        }
                        if (!filterIconEl) {
                            // putting icon in header text to have more options for positioning it
                            filterIconEl = DomHelper.createElement({
                                parent    : textEl,
                                tag       : 'div',
                                className : 'b-filter-icon',
                                dataset   : {
                                    filterText
                                }
                            });
                        }
                        else {
                            filterIconEl.dataset.filterText = filterText;
                        }
                        // latest applied filter distinguished with class to enable highlighting etc.
                        if (column.field === store.latestFilterField) filterIconEl.classList.add('b-latest');
                        headerEl.classList.add('b-filterable');
                        headerEl.classList.toggle('b-filter', isColumnFiltered);
                    }
                    column.meta.isFiltered = isColumnFiltered;
                }
            });
            if (reRenderRows) {
                client.refreshRows();
            }
        }
    }
    //endregion
    //region Filter
    applyFilter(column, filterConfig) {
        const { store } = this;
        // Must add the filter silently, so that the column gets a reference to its $filter
        // before the filter happens and events are broadcast.
        column.$filter = store.addFilter(this.injectColumnFilterConfig(column, filterConfig), true);
        // Apply the new set of store filters.
        store.filter();
    }
    injectColumnFilterConfig(column, filterConfig) {
        const { filterFn } = column.filterable;
        return ObjectHelper.assign(filterConfig, {
            ...column.filterable,
            ...filterConfig,
            property : column.field,
            // Only inject a filterBy configuration if the column has a custom filterBy
            [filterFn ? 'filterBy' : '_'] : function(record) {
                return filterFn({ value : this.value, record, operator : this.operator, property : this.property, column });
            }
        });
    }
    removeFilter(column, onlyForOperator) {
        if (!this.legacyMode && !column.filterable?.filterField) {
            for (const filter of this.getCurrentMultiFilters(column)) {
                if (!onlyForOperator || filter.operator === onlyForOperator) {
                    this.store.removeFilter(filter);
                }
            }
        }
        else {
            this.store.removeFilter(this.store.filters.getBy('property', column.field));
        }
    }
    disableFilter(column) {
        for (const filter of this.getCurrentMultiFilters(column)) {
            filter.disabled = true;
            this.store.filter(filter);
        }
        this.store.filter();
    }
    getCurrentMultiFilters(column) {
        return this.store.filters.values.filter(filter => filter.property === column.field);
    }
    getPopupDateItems(column, fieldType, filter, initialValue, store, changeCallback, closeCallback, filterField) {
        const
            me      = this,
            onClose = changeCallback;
        function onClear() {
            me.removeFilter(column);
        }
        function onKeydown({ event }) {
            if (event.key === 'Enter') {
                changeCallback();
            }
        }
        function onChange({ source, value }) {
            if (value == null) {
                onClear();
            }
            else {
                me.clearSiblingsFields(source);
                me.applyFilter(column, { operator : source.operator, value, displayValue : source._value, type : 'date' });
            }
        }
        return [
            ObjectHelper.assign({
                type        : 'date',
                ref         : 'on',
                placeholder : 'L{on}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-equal"></i>',
                value       : filter?.operator === 'sameDay' ? filter.value : initialValue,
                operator    : 'sameDay',
                onKeydown,
                onChange,
                onClose,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'date',
                ref         : 'before',
                placeholder : 'L{before}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-before"></i>',
                value       : filter?.operator === '<' ? filter.value : null,
                operator    : '<',
                onKeydown,
                onChange,
                onClose,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'date',
                ref         : 'after',
                cls         : 'b-last-row',
                placeholder : 'L{after}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-after"></i>',
                value       : filter?.operator === '>' ? filter.value : null,
                operator    : '>',
                onKeydown,
                onChange,
                onClose,
                onClear
            }, filterField)
        ];
    }
    getPopupNumberItems(column, fieldType, filter, initialValue, store, changeCallback, closeCallback, filterField) {
        const
            me    = this,
            onEsc = changeCallback;
        function onClear() {
            me.removeFilter(column);
        }
        function onKeydown({ event }) {
            if (event.key === 'Enter') {
                changeCallback();
            }
        }
        function onChange({ source, value }) {
            if (value == null) {
                onClear();
            }
            else {
                me.clearSiblingsFields(source);
                me.applyFilter(column, { operator : source.operator, value });
            }
        }
        return [
            ObjectHelper.assign({
                type        : 'number',
                placeholder : 'L{Filter.equals}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-equal"></i>',
                value       : filter?.operator === '=' ? filter.value : initialValue,
                operator    : '=',
                onKeydown,
                onChange,
                onEsc,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'number',
                placeholder : 'L{lessThan}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-less"></i>',
                value       : filter?.operator === '<' ? filter.value : null,
                operator    : '<',
                onKeydown,
                onChange,
                onEsc,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'number',
                cls         : 'b-last-row',
                placeholder : 'L{moreThan}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-more"></i>',
                value       : filter?.operator === '>' ? filter.value : null,
                operator    : '>',
                onKeydown,
                onChange,
                onEsc,
                onClear
            }, filterField)
        ];
    }
    clearSiblingsFields(sourceField) {
        this.filterEditorPopup?.items.forEach(field => {
            field !== sourceField && field?.clear();
        });
    }
    getPopupDurationItems(column, fieldType, filter, initialValue, store, changeCallback, closeCallback, filterField) {
        const
            me      = this,
            onEsc   = changeCallback,
            onClear = () => me.removeFilter(column);
        function onChange({ source, value }) {
            if (value == null) {
                onClear();
            }
            else {
                me.clearSiblingsFields(source);
                me.applyFilter(column, { operator : source.operator, value });
            }
        }
        return [
            ObjectHelper.assign({
                type        : 'duration',
                placeholder : 'L{Filter.equals}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-equal"></i>',
                value       : filter?.operator === '=' ? filter.value : initialValue,
                operator    : '=',
                onChange,
                onEsc,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'duration',
                placeholder : 'L{lessThan}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-less"></i>',
                value       : filter?.operator === '<' ? filter.value : null,
                operator    : '<',
                onChange,
                onEsc,
                onClear
            }, filterField),
            ObjectHelper.assign({
                type        : 'duration',
                cls         : 'b-last-row',
                placeholder : 'L{moreThan}',
                localeClass : me,
                clearable   : true,
                label       : '<i class="b-fw-icon b-icon-filter-more"></i>',
                value       : filter?.operator === '>' ? filter.value : null,
                operator    : '>',
                onChange,
                onEsc,
                onClear
            }, filterField)
        ];
    }
    getPopupStringItems(column, fieldType, filter, initialValue, store, changeCallback, closeCallback, filterField) {
        const me = this;
        return [ObjectHelper.assign({
            type        : 'text',
            cls         : 'b-last-row',
            placeholder : 'L{filter}',
            localeClass : me,
            clearable   : true,
            label       : '<i class="b-fw-icon b-icon-filter-equal"></i>',
            value       : filter ? (filter.value ?? filter) : initialValue,
            operator    : '*',
            onChange({ source, value }) {
                if (value === '') {
                    closeCallback();
                }
                else {
                    me.applyFilter(column, { operator : source.operator, value, displayValue : source.displayField && source.records ? source.records.map(rec => rec[source.displayField]).join(', ') : undefined });
                    // Leave multiselect filter combo visible to be able to select many items at once
                    if (!source.multiSelect) {
                        changeCallback();
                    }
                }
            },
            onClose : changeCallback,
            onClear : closeCallback
        }, filterField)];
    }
    /**
     * Get fields to display in filter popup.
     * @param {Grid.column.Column} column Column
     * @param fieldType Type of field, number, date etc.
     * @param filter Current filter
     * @param initialValue
     * @param store Grid store
     * @param changeCallback Callback for when filter has changed
     * @param closeCallback Callback for when editor should be closed
     * @param filterField filter field
     * @returns {*}
     * @private
     */
    getPopupItems(column, fieldType, filter, initialValue, store, changeCallback, closeCallback, filterField) {
        const me = this;
        if (me.useLegacyModeForColumn(column) || filterField) {
            switch (fieldType) {
                case 'date':
                    return me.getPopupDateItems(...arguments);
                case 'number':
                    return me.getPopupNumberItems(...arguments);
                case 'duration':
                    return me.getPopupDurationItems(...arguments);
                default:
                    return me.getPopupStringItems(...arguments);
            }
        }
        return [me.getFieldFilterPickerGroup(column)];
    }
    getAllowedOperators(column) {
        let { allowedOperators } = this;
        if (column?.filterable?.allowedOperators) {
            allowedOperators = allowedOperators ?? [];
            allowedOperators.push(...column.filterable.allowedOperators);
        }
        return allowedOperators;
    }
    getFieldFilterPickerGroup(column) {
        const
            me = this,
            filterType = me.getFilterType(column),
            allowedOperators = me.getAllowedOperators(column),
            pickerGroupConfig = {
                fields : {
                    [column.field] : {
                        type  : filterType,
                        title : column.text ?? column.field
                    }
                },
                ...me.pickerConfig,
                type              : 'gridfieldfilterpickergroup',
                ref               : 'pickerGroup',
                limitToProperty   : column.field,
                grid              : me.client,
                propertyFieldCls  : 'b-transparent property-field',
                operatorFieldCls  : 'b-transparent operator-field',
                valueFieldCls     : 'b-transparent value-field',
                internalListeners : {
                    beforeAddFilter : ({ filter }) => {
                        me.injectColumnFilterConfig(column, filter);
                    },
                    remove  : me.onPopupFilterRemove,
                    keydown : me.onPopupKeydown,
                    thisObj : me
                },
                triggerChangeOnInput : false
            };
        if (allowedOperators) {
            pickerGroupConfig.operators = {
                [filterType] : FieldFilterPicker.defaultOperators[filterType].filter(({ value }) =>
                    allowedOperators.includes(value))
            };
        }
        return pickerGroupConfig;
    }
    onPopupFilterRemove() {
        if (this.closeEmptyPopup && this.filterEditorPopup.widgetMap.pickerGroup.filters.length === 0) {
            // Must delay so normal change event happens and store gets filtered
            this.delay(this.closeFilterEditor, 0);
        }
    }
    onPopupKeydown({ event }) {
        if (event.key === 'Enter') {
            // Must delay so normal change event happens and store gets filtered
            this.delay(this.closeFilterEditor, 0);
        }
    }
    /**
     * Shows a popup where a filter can be edited.
     * @param {Grid.column.Column|String} column Column to show filter editor for
     * @param {*} [value] The initial value of the filter value input
     * @param {String} [operator] The initial operator of the filter operator selector (non-legacy mode)
     * @param {Boolean} [forceAddBlank] Whether to add a blank filter row even if other filters exist
     *                  (non-legacy mode; default false)
     */
    showFilterEditor(column, value, operator, forceAddBlank = false) {
        column = this.client.columns.getById(column);
        const
            me         = this,
            { store }  = me,
            headerEl   = column.element,
            filter     = store.filters.getBy('property', column.field),
            fieldType  = me.getFilterType(column),
            legacyMode = me.useLegacyModeForColumn(column);
        if (!me.canFilterColumn(column)) {
            return;
        }
        // Destroy previous filter popup
        me.closeFilterEditor();
        const items = me.getPopupItems(
            column,
            fieldType,
            // Only pass filter if it's not an internal filter
            filter?.internal ? null : filter,
            value,
            store,
            me.closeFilterEditor,
            () => {
                me.removeFilter(column);
                me.closeFilterEditor();
            },
            column.filterable.filterField
        );
        // Localize placeholders
        items.forEach(item => item.placeholder = item.placeholder ? this.L(item.placeholder) : item.placeholder);
        me.filterEditorPopup = new Popup({
            forElement : headerEl,
            owner      : me.client,
            cls        : new DomClassList('b-filter-popup', {
                'b-filter-popup-legacymode' : legacyMode
            }),
            scrollAction : 'realign',
            layout       : {
                type  : 'vbox',
                align : 'stretch'
            },
            items
        });
        if (!legacyMode) {
            // Add a blank default filter if none present, or if forceAddBlank=true
            if (forceAddBlank || !store?.filters.find(filter => filter.property === column.field)) {
                const
                    allowedOperators   =  me.getAllowedOperators(column),
                    operatorsByType    = me.filterEditorPopup.widgetMap.pickerGroup.operators ??
                        FieldFilterPicker.configurable.operators,
                    availableOperators = operatorsByType[fieldType];
                let defaultOperator = me.defaultOperators[fieldType];
                // Use defaultOperator if it's allowed by the picker, otherwise take the first option
                if (!availableOperators.find(({ value }) => value === defaultOperator)) {
                    defaultOperator = availableOperators[0]?.value;
                }
                if (allowedOperators && !allowedOperators.includes(defaultOperator)) {
                    defaultOperator = allowedOperators[0];
                }
                me.filterEditorPopup.widgetMap.pickerGroup.addFilter({
                    type     : fieldType,
                    property : column.field,
                    operator : operator ?? defaultOperator,
                    value
                });
            }
            me.filterEditorPopup.items[0].focus();
        }
    }
    /**
     * Close the filter editor.
     */
    closeFilterEditor() {
        // Must defer the destroy because it may be closed by an event like a "change" event where
        // there may be plenty of code left to execute which must not execute on destroyed objects.
        this.filterEditorPopup?.setTimeout(this.filterEditorPopup.destroy);
        this.filterEditorPopup = null;
    }
    //endregion
    //region Context menu
    getFilterType(column) {
        const
            me        = this,
            { store } = me,
            fieldName = column.field,
            field     = fieldName && store.modelClass.getFieldDefinition(fieldName),
            type      = column.filterType ?? fieldTypeMap[column.type] ?? fieldTypeMap[field?.type],
            relation  = store?.modelRelations?.find(({ foreignKey }) => foreignKey === fieldName);
        if (relation) {
            return 'relation';
        }
        else if (type === 'auto' && store && !me.useLegacyModeForColumn(column)) {
            if (store.totalCount > 0) {
                me.inferredTypes[fieldName] = FieldFilterPicker.inferFieldType(store, fieldName);
            }
            return me.inferredTypes[fieldName] ?? 'auto';
        }
        else if (type) {
            return fieldTypeMap[type];
        }
        return 'auto';
    }
    populateCellMenuWithDateItems({ column, record, items }) {
        const
            property = column.field,
            type     = this.getFilterType(column);
        if (type === 'date') {
            const
                me       = this,
                value    = record.getValue(property),
                filter   = operator => {
                    me.applyFilter(column, {
                        operator,
                        value,
                        displayValue : column.formatValue ? column.formatValue(value) : value,
                        type         : 'date'
                    });
                };
            items.filterDateEquals = {
                text        : 'L{on}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-equal',
                cls         : 'b-separator',
                weight      : 300,
                disabled    : me.disabled,
                onItem      : () => filter('=')
            };
            items.filterDateBefore = {
                text        : 'L{before}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-before',
                weight      : 310,
                disabled    : me.disabled,
                onItem      : () => filter('<')
            };
            items.filterDateAfter = {
                text        : 'L{after}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-after',
                weight      : 320,
                disabled    : me.disabled,
                onItem      : () => filter('>')
            };
        }
    }
    populateCellMenuWithNumberItems({ column, record, items }) {
        const
            property = column.field,
            type     = this.getFilterType(column);
        if (type === 'number') {
            const
                me       = this,
                value    = record.getValue(property),
                filter   = operator => {
                    me.applyFilter(column, { operator, value });
                };
            items.filterNumberEquals = {
                text        : 'L{equals}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-equal',
                cls         : 'b-separator',
                weight      : 300,
                disabled    : me.disabled,
                onItem      : () => filter('=')
            };
            items.filterNumberLess = {
                text        : 'L{lessThan}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-less',
                weight      : 310,
                disabled    : me.disabled,
                onItem      : () => filter('<')
            };
            items.filterNumberMore = {
                text        : 'L{moreThan}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-more',
                weight      : 320,
                disabled    : me.disabled,
                onItem      : () => filter('>')
            };
        }
    }
    populateCellMenuWithDurationItems({ column, record, items }) {
        const
            type = this.getFilterType(column);
        if (type === 'duration') {
            const
                me       = this,
                value    = column.getFilterableValue(record),
                filter   = operator => {
                    me.applyFilter(column, { operator, value });
                };
            items.filterDurationEquals = {
                text        : 'L{equals}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-equal',
                cls         : 'b-separator',
                weight      : 300,
                disabled    : me.disabled,
                onItem      : () => filter('=')
            };
            items.filterDurationLess = {
                text        : 'L{lessThan}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-less',
                weight      : 310,
                disabled    : me.disabled,
                onItem      : () => filter('<')
            };
            items.filterDurationMore = {
                text        : 'L{moreThan}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-more',
                weight      : 320,
                disabled    : me.disabled,
                onItem      : () => filter('>')
            };
        }
    }
    populateCellMenuWithStringItems({ column, record, items }) {
        const type = this.getFilterType(column);
        if (!/(date|number|duration)/.test(type)) {
            const
                me       = this,
                value    = column.getFilterableValue(record),
                operator = column.filterable.filterField?.operator ?? '*';
            items.filterStringEquals = {
                text        : 'L{equals}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-equal',
                cls         : 'b-separator',
                weight      : 300,
                disabled    : me.disabled,
                onItem      : () => me.applyFilter(column, { value, operator })
            };
        }
    }
    /**
     * In non-legacy mode, gets the cell/header context menu items: a top-level Filter item having a submenu with
     * operator and edit/remove options). Not used by legacy mode.
     * @private
     */
    getMenuItems(column, record) {
        const
            me = this,
            submenuItems = {},
            type = me.getFilterType(column);
        if (column.filterable === false) {
            return {};
        }
        if (allowedFilterTypes[type]) {
            const
                value = record ? column.getFilterableValue(record) : undefined,
                activeOperators = me.getCurrentMultiFilters(column)
                    .filter(filter => !filter.disabled)
                    .map(({ operator }) => operator),
                maxArgCount = record ? 1 : undefined,
                columnAllowedOperators =  me.getAllowedOperators(column),
                allowedOperators = FieldFilterPicker.defaultOperators[type]
                    .filter(({ value, argCount, isArrayValued }) =>
                        !(argCount > maxArgCount || isArrayValued) &&
                        (!columnAllowedOperators || columnAllowedOperators.includes(value)));
            let weight = 300;
            for (const { value: operator, text, argCount } of allowedOperators) {
                const key = `filter${StringHelper.capitalize(type)}${StringHelper.capitalize(operator)}`;
                submenuItems[key] = {
                    text     : StringHelper.capitalize(FieldFilterPicker.L(text)),
                    weight   : weight += 10,
                    icon     : activeOperators.includes(operator) ? 'b-icon b-icon-check' : null,
                    disabled : me.disabled,
                    cls      : menuItemsWithSeparators[key] ? 'b-separator' : null,
                    onItem   : () => me.onOperatorMenuItem(column, value, operator, argCount)
                };
            }
        }
        if (column.meta.isFiltered) {
            Object.assign(submenuItems, me.getMenuItemsForFilteredColumn(column, record !== undefined));
        }
        return {
            filterMenu : {
                text        : 'L{filter}',
                localeClass : me,
                menu        : submenuItems,
                icon        : 'b-fw-icon b-icon-filter',
                weight      : record ? 400 : 100
            }
        };
    }
    /**
     * Handle clicking on an operator item in the filter submenu.
     * @param {Grid.column.Column} column The column to which the menu belongs
     * @param {*} value The cell value if this context menu belongs to a grid cell, undefined if header menu
     * @param {String} operator The selected operator, e.g. `'='`, `'isToday'`. See `CollectionFilter`.
     * @param {String} type The selected operator, e.g. `'='`, `'isToday'`. See `CollectionFilter`.
     * @param {Number} argCount The number of arguments required by the operator
     * @private
     */
    onOperatorMenuItem(column, value, operator, argCount = 1) {
        const
            me        = this,
            type      = me.getFilterType(column),
            wasActive = me.getCurrentMultiFilters(column)
                .find(filter => !filter.disabled && filter.operator === operator);
        if (wasActive) {
            me.removeFilter(column, operator);
        }
        else {
            if (argCount == 0 || value !== undefined) {
                me.applyFilter(column, {
                    property      : column.field,
                    operator,
                    type,
                    value         : argCount === 1 ? value : null,
                    caseSensitive : false,
                    disabled      : value == null && argCount > 0   // Can't apply filter yet; incomplete
                });
            }
            else {
                me.showFilterEditor(column, null, operator, true);
            }
        }
    }
    /**
     * Get the context menu items (cell and header) that apply when the column is already filtered, e.g. edit,
     * remove, disable. Used by both legacy and regular modes.
     * @param {Grid.column.Column} column The column to which the menu pertains
     * @param {Boolean} isCellMenu Whether this is a cell's context menu (not header)
     * @returns {Object<String,MenuItemConfig>} An `items` config containing the appropriate menu item configs
     * @private
     */
    getMenuItemsForFilteredColumn(column, isCellMenu) {
        const
            me = this,
            canRemoveFilter = !me.disabled && (me.legacyMode || me.columnHasRemovableFilters(column));
        return {
            // Don't show 'edit' in legacy mode cell menu (legacy mode header menu handled elsewhere)
            filterEdit : me.legacyMode ? undefined : {
                text        : 'L{editFilter}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-edit',
                cls         : 'b-separator',
                weight      : 500,
                disabled    : !canRemoveFilter,
                onItem      : () => me.showFilterEditor(column)
            },
            filterRemove : {
                text        : 'L{removeFilter}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-remove',
                cls         : 'b-separator',
                weight      : 510,
                disabled    : !canRemoveFilter,
                onItem      : () => me.removeFilter(column)
            },
            filterDisable : {
                text        : 'L{disableFilter}',
                localeClass : me,
                icon        : 'b-fw-icon b-icon-filter-disable',
                weight      : 520,
                disabled    : me.disabled || !me.columnHasEnabledFilters(column),
                onItem      : () => me.disableFilter(column)
            }
        };
    }
    /**
     * Add menu items for filtering.
     * @param {Object} options Contains menu items and extra data retrieved from the menu target.
     * @param {Grid.column.Column} options.column Column for which the menu will be shown
     * @param {Core.data.Model} options.record Record for which the menu will be shown
     * @param {Object<String,MenuItemConfig|Boolean|null>} options.items A named object to describe menu items
     * @internal
     */
    populateCellMenu({ column, record, items, ...rest }) {
        const me = this;
        if (me.canFilterColumn(column) && !record.isSpecialRow) {
            if (me.useLegacyModeForColumn(column)) {
                me.populateCellMenuWithDateItems({ column, record, items, ...rest });
                me.populateCellMenuWithNumberItems({ column, record, items, ...rest });
                me.populateCellMenuWithDurationItems({ column, record, items, ...rest });
                me.populateCellMenuWithStringItems({ column, record, items, ...rest });
                if (column.meta.isFiltered) {
                    Object.assign(items, me.getMenuItemsForFilteredColumn(column, true));
                }
            }
            else {
                // New default UI allows all applicable operators defined by adv filter UI
                Object.assign(items, me.getMenuItems(column, record));
            }
        }
    }
    /**
     * Used to determine whether the 'remove filters' menu item should be enabled.
     * @internal
     */
    columnHasRemovableFilters(column) {
        const me = this;
        return Boolean(me.getCurrentMultiFilters(column).find(filter =>
            !me.canDeleteFilter || (me.callback(me.canDeleteFilter, me, [filter]) !== false)));
    }
    /**
     * Used to determine whether the 'disable filters' menu item should be enabled.
     * @internal
     */
    columnHasEnabledFilters(column) {
        return Boolean(this.getCurrentMultiFilters(column).find(filter => !filter.disabled));
    }
    /**
     * Add menu item for removing filter if column is filtered.
     * @param {Object} options Contains menu items and extra data retrieved from the menu target.
     * @param {Grid.column.Column} options.column Column for which the menu will be shown
     * @param {Object<String,MenuItemConfig|Boolean|null>} options.items A named object to describe menu items
     * @internal
     */
    populateHeaderMenu({ column, items }) {
        const me = this;
        if (me.useLegacyModeForColumn(column)) {
            if (column.meta.isFiltered) {
                items.filterEdit = {
                    text        : 'L{editFilter}',
                    localeClass : me,
                    weight      : 100,
                    icon        : 'b-fw-icon b-icon-filter',
                    cls         : 'b-separator',
                    disabled    : me.disabled,
                    onItem      : () => me.showFilterEditor(column)
                };
                items.filterRemove = {
                    text        : 'L{removeFilter}',
                    localeClass : me,
                    weight      : 110,
                    icon        : 'b-fw-icon b-icon-remove',
                    disabled    : me.disabled || (!me.legacyMode && !me.columnHasRemovableFilters(column)),
                    onItem      : () => me.removeFilter(column)
                };
                items.filterDisable = {
                    text        : 'L{disableFilter}',
                    localeClass : me,
                    icon        : 'b-fw-icon b-icon-filter-disable',
                    weight      : 115,
                    disabled    : me.disabled || !me.columnHasEnabledFilters(column),
                    onItem      : () => me.disableFilter(column)
                };
            }
            else if (me.canFilterColumn(column)) {
                items.filter = {
                    text        : 'L{filter}',
                    localeClass : me,
                    weight      : 100,
                    icon        : 'b-fw-icon b-icon-filter',
                    cls         : 'b-separator',
                    disabled    : me.disabled,
                    onItem      : () => me.showFilterEditor(column)
                };
            }
        }
        else {
            Object.assign(items, me.getMenuItems(column));
        }
    }
    useLegacyModeForColumn(column) {
        return this.legacyMode || (column.filterable?.filterField != undefined);
    }
    canFilterColumn(column) {
        return (column.filterable !== false &&
            (column.filterable?.filter === true ||
                (column.filterable?.filter !== false && this.defaultEnabled !== false)));
    }
    //endregion
    //region Events
    // Intercept filtering by a column that has a custom filtering fn, and inject that fn
    onStoreBeforeFilter({ filters }) {
        const
            { columns } = this.client,
            filtersByColumn = ArrayHelper.groupBy(filters, 'property');
        for (const [property, columnFilters] of Object.entries(filtersByColumn)) {
            const column = columns.find(col => this.canFilterColumn(col) && col.field === property);
            if (column) {
                column.$filters = columnFilters.map((filter, index) => {
                    if ((filter.columnOwned || this.prioritizeColumns) && !filter.internal && column?.filterable?.filterFn) {
                        // If the filter was sourced from the store, replace it with a filter which
                        // uses the column's filterFn
                        const $filter =
                            new CollectionFilter({
                                id           : filter.id ?? `${property}-${index}-${Date.now()}`,
                                columnOwned  : true,
                                property,
                                operator     : filter.operator,
                                value        : filter.value,
                                displayValue : filter.displayValue,
                                disabled     : filter.disabled,
                                filterBy(record) {
                                    return column.filterable.filterFn.call(this, {
                                        value    : this.value,
                                        record,
                                        operator : this.operator,
                                        property : this.property,
                                        column
                                    });
                                }
                            });
                        filters.splice(filters.indexOf(filter), 1, $filter);
                        return $filter;
                    }
                    return filter;
                });
            }
        }
    }
    /**
     * Store filtered; refresh headers.
     * @private
     */
    onStoreFilter() {
        // Pass false to not refresh rows.
        // Store's refresh event will refresh the rows.
        this.refreshHeaders(false);
    }
    /**
     * Called after headers are rendered, make headers match stores initial sorters
     * @private
     */
    renderHeader() {
        this.refreshHeaders(false);
    }
    /**
     * Called when user clicks on the grid. Only care about clicks on the filter icon.
     * @param {MouseEvent} event
     * @private
     */
    onElementClick({ target }) {
        if (this.filterEditorPopup) {
            this.closeFilterEditor();
        }
        if (target.classList.contains('b-filter-icon')) {
            const headerEl = target.closest('.b-grid-header');
            this.showFilterEditor(headerEl.dataset.columnId);
            return false;
        }
    }
    /**
     * Called when user presses F-key grid.
     * @param {MouseEvent} event
     * @private
     */
    showFilterEditorByKey({ target }) {
        const headerEl = target.matches('.b-grid-header') && target;
        // Header must be focused
        if (headerEl) {
            this.showFilterEditor(headerEl.dataset.columnId);
        }
        return Boolean(headerEl);
    }
    // Only care about F key when a filterable header is focused
    isActionAvailable({ event, actionName }) {
        if (actionName === 'showFilterEditorByKey') {
            const
                headerElement = event.target.closest('.b-grid-header'),
                column = headerElement && this.client.columns.find(col => col.id === headerElement.dataset.columnId);
            return Boolean(column?.filterable);
        }
    }
    //endregion
}
Filter._$name = 'Filter'; GridFeatureManager.registerFeature(Filter);
