import Store from '../../Core/data/Store.js';
import DH from '../../Core/helper/DateHelper.js';
import TimeSpan from '../model/TimeSpan.js';
import PresetManager from '../preset/PresetManager.js';
/**
 * @module Scheduler/data/TimeAxis
 */
// Micro-optimized version of TimeSpan for faster reading. Hit a lot and since it is internal fields are guaranteed to
// not be remapped and changes won't be batches, so we can always return raw value from data avoiding all additional
// checks and logic
class Tick extends TimeSpan {
    // Only getters on purpose, we do not support manipulating ticks
    get startDate() {
        return this.data.startDate;
    }
    get endDate() {
        return this.data.endDate;
    }
}
/**
 * A class representing the time axis of the scheduler. The scheduler timescale is based on the ticks generated by this
 * class. This is a pure "data" (model) representation of the time axis and has no UI elements.
 *
 * The time axis can be {@link #config-continuous} or not. In continuous mode, each timespan starts where the previous
 * ended, and in non-continuous mode there can be gaps between the ticks.
 * A non-continuous time axis can be used when want to filter out certain periods of time (like weekends) from the time
 * axis.
 *
 * To create a non-continuous time axis you have 2 options. First, you can create a time axis containing only the time
 * spans of interest. To do that, subclass this class and override the {@link #property-generateTicks} method.
 *
 * The other alternative is to call the {@link #function-filterBy} method, passing a function to it which should return
 * `false` if the time tick should be filtered out. Calling {@link Core.data.mixin.StoreFilter#function-clearFilters}
 * will return you to a full time axis.
 *
 * @extends Core/data/Store
 */
export default class TimeAxis extends Store {
    //region Events
    /**
     * Fires before the timeaxis is about to be reconfigured (e.g. new start/end date or unit/increment). Return `false`
     * to abort the operation.
     * @event beforeReconfigure
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     * @param {Date} startDate The new time axis start date
     * @param {Date} endDate The new time axis end date
     * @preventable
     */
    /**
     * Event that is triggered when we end reconfiguring and everything UI-related should be done
     * @event endReconfigure
     * @private
     */
    /**
     * Fires when the timeaxis has been reconfigured (e.g. new start/end date or unit/increment)
     * @event reconfigure
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     */
    /**
     * Fires if all the ticks in the timeaxis are filtered out. After firing the filter is temporarily disabled to
     * return the time axis to a valid state. A disabled filter will be re-enabled the next time ticks are regenerated
     * @event invalidFilter
     * @param {Scheduler.data.TimeAxis} source The time axis instance
     */
    //endregion
    //region Default config
    /**
     * @hideconfigs lazyLoad
     */
    /**
     * @hideproperties lazyLoad
     */
    /**
     * @hideevents lazyLoadStarted, lazyLoadEnded
     */
    /**
     * @hidefunctions requestData
     */
    static get defaultConfig() {
        return {
            modelClass : Tick,
            /**
             * Set to `false` if the timeline is not continuous, e.g. the next timespan does not start where the previous ended (for example skipping weekends etc).
             * @config {Boolean}
             * @default
             */
            continuous : true,
            originalContinuous : null,
            /**
             * Include only certain hours or days in the time axis (makes it `continuous : false`). Accepts and object
             * with `day` and `hour` properties:
             * ```
             * const scheduler = new Scheduler({
             *     timeAxis : {
             *         include : {
             *              // Do not display hours after 17 or before 9 (only display 9 - 17). The `to´ value is not
             *              // included in the time axis
             *              hour : {
             *                  from : 9,
             *                  to   : 17
             *              },
             *              // Do not display sunday or saturday
             *              day : [0, 6]
             *         }
             *     }
             * }
             * ```
             * In most cases we recommend that you use Scheduler's workingTime config instead. It is easier to use and
             * makes sure all parts of the Scheduler gets updated.
             * @config {Object}
             */
            include : null,
            /**
             * Automatically adjust the timespan when generating ticks with {@link #property-generateTicks} according to
             * the `viewPreset` configuration. Setting this to false may lead to shifting time/date of ticks.
             * @config {Boolean}
             * @default
             */
            autoAdjust : true,
            //isConfigured : false,
            // in case of `autoAdjust : false`, the 1st and last ticks can be truncated, containing only part of the normal tick
            // these dates will contain adjusted start/end (like if the tick has not been truncated)
            adjustedStart    : null,
            adjustedEnd      : null,
            // the visible position in the first tick, can actually be > 1 because the adjustment is done by the `mainUnit`
            visibleTickStart : null,
            // the visible position in the first tick, is always ticks count - 1 < value <= ticks count, in case of autoAdjust, always = ticks count
            visibleTickEnd   : null,
            tickCache : {},
            viewPreset       : null,
            maxTraverseTries : 100,
            useRawData       : {
                disableDuplicateIdCheck : true,
                disableDefaultValue     : true,
                disableTypeConversion   : true
            }
        };
    }
    static get configurable() {
        return {
            /**
             * Method generating the ticks for this time axis. Should return a **non-empty** array of ticks. Each tick
             * is an object of the following structure:
             * ```
             * {
             *    startDate : ..., // start date
             *    endDate   : ...  // end date
             * }
             * ```
             * To see it in action please check out our [TimeAxis](https://bryntum.com/products/scheduler/examples/timeaxis/)
             * example and navigate to "Compressed non-working time" tab.
             *
             * @param {Date} axisStartDate The start date of the interval
             * @param {Date} axisEndDate The end date of the interval
             * @param {DurationUnit} unit The unit of the time axis
             * @param {Number} increment The increment for the unit specified.
             * @returns {TimeSpanConfig[]} ticks The ticks representing the time axis
             * @config {Function}
             */
            generateTicks : null,
            unit                : null,
            increment           : null,
            resolutionUnit      : null,
            resolutionIncrement : null,
            mainUnit            : null,
            shiftUnit           : null,
            shiftIncrement      : 1,
            defaultSpan         : 1,
            weekStartDay : null,
            // Used to force resolution to match whole ticks, to snap accordingly when using fillTicks in the UI
            forceFullTicks : null
        };
    }
    //endregion
    //region Init
    // private
    construct(config) {
        const me = this;
        super.construct(config);
        me.originalContinuous = me.continuous;
        me.ion({
            change : ({ action }) => {
                // If the change was due to filtering, there will be a refresh event
                // arriving next, so do not reconfigure
                if (action !== 'filter') {
                    me.trigger('reconfigure', { supressRefresh : false });
                }
            },
            refresh        : () => me.trigger('reconfigure', { supressRefresh : false }),
            endreconfigure : event => me.trigger('reconfigure', event)
        });
        if (me.startDate) {
            me.internalOnReconfigure();
            me.trigger('reconfigure');
        }
        else if (me.viewPreset) {
            const range  = me.getAdjustedDates(new Date());
            me.startDate = range.startDate;
            me.endDate   = range.endDate;
        }
    }
    get isTimeAxis() {
        return true;
    }
    //endregion
    //region Configuration (reconfigure & consumePreset)
    /**
     * Reconfigures the time axis based on the config object supplied and generates the new 'ticks'.
     * @param {Object} config
     * @param {Boolean} [suppressRefresh]
     * @private
     */
    reconfigure(config, suppressRefresh = false, preventThrow = false) {
        const
            me         = this,
            normalized = me.getAdjustedDates(config.startDate, config.endDate),
            oldConfig  = {};
        if (me.trigger('beforeReconfigure', { startDate : normalized.startDate, endDate : normalized.endDate, config }) !== false) {
            me.trigger('beginReconfigure');
            me._configuredStartDate = config.startDate;
            me._configuredEndDate   = config.endDate;
            // Collect old values for end event
            for (const propName in config) {
                oldConfig[propName] = me[propName];
            }
            const viewPresetChanged = config.viewPreset && config.viewPreset !== me.viewPreset;
            // If changing viewPreset, try to gracefully recover if an applied filter results in an empty view
            if (viewPresetChanged) {
                preventThrow = me.isFiltered;
                me.filters.forEach(f => f.disabled = false);
            }
            Object.assign(me, config);
            if (me.internalOnReconfigure(preventThrow, viewPresetChanged) === false) {
                return false;
            }
            me.trigger('endReconfigure', { suppressRefresh, config, oldConfig });
        }
    }
    internalOnReconfigure(preventThrow = false, viewPresetChanged) {
        const me = this;
        me.isConfigured = true;
        const
            adjusted   = me.getAdjustedDates(me.startDate, me.endDate, true),
            normalized = me.getAdjustedDates(me.startDate, me.endDate),
            start      = normalized.startDate,
            end        = normalized.endDate;
        if (start >= end) {
            throw new Error(`Invalid start/end dates. Start date must be less than end date. Start date: ${start}. End date: ${end}.`);
        }
        const
            { unit, increment = 1 } = me,
            ticks                   = me.generateTicks(start, end, unit, increment) ||
                                      // Offer a fallback in case user did not generate any ticks at all.
                                      me.constructor.prototype.generateTicks.call(me, start, end, unit, increment);
        // Suspending to be able to detect an invalid filter
        me.suspendEvents();
        me.maintainFilter = preventThrow;
        me.data           = ticks;
        me.maintainFilter = false;
        const { count } = me;
        if (count === 0) {
            if (preventThrow) {
                if (viewPresetChanged) {
                    me.disableFilters();
                }
                me.resumeEvents();
                return false;
            }
            throw new Error('Invalid time axis configuration or filter, please check your input data.');
        }
        // start date is cached, update it to fill after generated ticks
        me.startDate = me.first.startDate;
        me.endDate   = me.last.endDate;
        me.resumeEvents();
        if (me.isContinuous) {
            me.adjustedStart = adjusted.startDate;
            me.adjustedEnd   = DH.getNext(count > 1 ? ticks[count - 1].startDate : adjusted.startDate, unit, increment, me.weekStartDay);
        }
        else {
            me.adjustedStart = me.startDate;
            me.adjustedEnd   = me.endDate;
        }
        me.updateVisibleTickBoundaries();
        me.updateTickCache(true);
    }
    updateVisibleTickBoundaries() {
        const
            me = this,
            {
                count,
                unit,
                startDate,
                endDate,
                weekStartDay,
                increment = 1
            }  = me;
        // Denominator is amount of milliseconds in a full tick (unit * increment). Normally we use 30 days in a month
        // and 365 days in a year. But if month is 31 day long or year is a leap one standard formula might calculate
        // wrong value. e.g. if we're rendering 1 day from August, formula goes like (2021-08-31 - 2021-08-02) / 30 = 1
        // and renders full tick which is incorrect. For such cases we need to adjust denominator to a correct one.
        // Thankfully there are only a few of them - month, year and day with DST transition.
        const
            startDenominator = DH.getNormalizedUnitDuration(startDate, unit) * increment,
            endDenominator   = DH.getNormalizedUnitDuration(endDate, unit) * increment;
        // if visibleTickStart > 1 this means some tick is fully outside of the view - we are not interested in it and want to
        // drop it and adjust "adjustedStart" accordingly
        do {
            me.visibleTickStart = (startDate - me.adjustedStart) / startDenominator;
            if (me.autoAdjust) me.visibleTickStart = Math.floor(me.visibleTickStart);
            if (me.visibleTickStart >= 1) me.adjustedStart = DH.getNext(me.adjustedStart, unit, increment, weekStartDay);
        } while (me.visibleTickStart >= 1);
        do {
            me.visibleTickEnd = count - (me.adjustedEnd - endDate) / endDenominator;
            if (count - me.visibleTickEnd >= 1) me.adjustedEnd = DH.getNext(me.adjustedEnd, unit, -1, weekStartDay);
        } while (count - me.visibleTickEnd >= 1);
        // This flag indicates that the time axis starts exactly on a tick boundary and finishes on a tick boundary
        // This is used as an optimization flag by TimeAxisViewModel.createHeaderRow
        me.fullTicks = !me.visibleTickStart && me.visibleTickEnd === count;
    }
    /**
     * Get the currently used time unit for the ticks
     * @readonly
     * @member {DurationUnit} unit
     */
    /**
     * Get/set currently used preset
     * @property {Scheduler.preset.ViewPreset}
     */
    get viewPreset() {
        return this._viewPreset;
    }
    set viewPreset(preset) {
        const me = this;
        preset = PresetManager.getPreset(preset);
        if (!preset.isViewPreset) {
            throw new Error('TimeAxis must be configured with the ViewPreset instance that the Scheduler is using');
        }
        me._viewPreset = preset;
        Object.assign(me, {
            unit      : preset.bottomHeader.unit,
            increment : preset.bottomHeader.increment || 1,
            resolutionUnit      : preset.timeResolution.unit,
            resolutionIncrement : preset.timeResolution.increment,
            mainUnit       : preset.mainHeader.unit,
            shiftUnit      : preset.shiftUnit || preset.mainHeader.unit,
            shiftIncrement : preset.shiftIncrement || 1,
            defaultSpan : preset.defaultSpan || 1,
            presetName  : preset.id,
            // Weekview columns are updated upon 'datachanged' event on this object.
            // We have to pass headers in order to render them correctly (timeAxisViewModel is incorrect in required time)
            headers : preset.headers
        });
    }
    //endregion
    //region Getters & setters
    get weekStartDay() {
        return this._weekStartDay ?? DH.weekStartDay;
    }
    // private
    get resolution() {
        return {
            unit      : this.resolutionUnit,
            increment : this.resolutionIncrement
        };
    }
    // private
    set resolution(resolution) {
        this.resolutionUnit      = resolution.unit;
        this.resolutionIncrement = resolution.increment;
    }
    get resolutionUnit() {
        return this.forceFullTicks ? this.unit : this._resolutionUnit;
    }
    get resolutionIncrement() {
        return this.forceFullTicks ? this.increment : this._resolutionIncrement;
    }
    //endregion
    //region Timespan & resolution
    /**
     * Changes the time axis timespan to the supplied start and end dates.
     *
     * **Note** This does **not** preserve the temporal scroll position. You may use
     * {@link Scheduler.view.Scheduler#function-setTimeSpan} to set the time axis and
     * maintain temporal scroll position (if possible).
     * @param {Date} newStartDate The new start date
     * @param {Date} [newEndDate] The new end date
     */
    setTimeSpan(newStartDate, newEndDate, preventThrow = false) {
        // If providing a 0 span range, add default range
        if (newEndDate && newStartDate - newEndDate === 0) {
            newEndDate = null;
        }
        const
            me                     = this,
            { startDate, endDate } = me.getAdjustedDates(newStartDate, newEndDate);
        if (me.startDate - startDate !== 0 || me.endDate - endDate !== 0) {
            return me.reconfigure({
                startDate,
                endDate
            }, false, preventThrow);
        }
    }
    /**
     * Moves the time axis by the passed amount and unit.
     *
     * NOTE: When using a filtered TimeAxis the result of `shift()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.
     *
     * @param {Number} amount The number of units to jump
     * @param {String} [unit] The unit (Day, Week etc)
     */
    shift(amount, unit = this.shiftUnit) {
        const me = this;
        let { startDate, endDate } = me;
        // Use unfiltered start and end dates when shifting a filtered time axis, to lessen risk of messing it up.
        // Still not guaranteed to work though
        if (me.isFiltered) {
            startDate = me.allRecords[0].startDate;
            endDate   = me.allRecords[me.totalCount - 1].endDate;
        }
        // Hack for filtered time axis, for example if weekend is filtered out and you shiftPrev() day from monday
        let tries = 0;
        do {
            startDate = DH.add(startDate, amount, unit);
            endDate   = DH.add(endDate, amount, unit);
        } while (tries++ < me.maxTraverseTries && me.setTimeSpan(startDate, endDate, {
            preventThrow : true
        }) === false);
    }
    /**
     * Moves the time axis forward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement`
     * config of the current view preset.
     *
     * NOTE: When using a filtered TimeAxis the result of `shiftNext()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.
     *
     * @param {Number} [amount] The number of units to jump forward
     */
    shiftNext(amount = this.shiftIncrement) {
        this.shift(amount);
    }
    /**
     * Moves the time axis backward in time in units specified by the view preset `shiftUnit`, and by the amount specified by the `shiftIncrement` config of the current view preset.
     *
     * NOTE: When using a filtered TimeAxis the result of `shiftPrev()` cannot be guaranteed, it might shift into a
     * filtered out span. It tries to be smart about it by shifting from unfiltered start and end dates.
     * If that solution does not work for your filtering setup, please call {@link #function-setTimeSpan} directly
     * instead.
     *
     * @param {Number} [amount] The number of units to jump backward
     */
    shiftPrevious(amount = this.shiftIncrement) {
        this.shift(-amount);
    }
    //endregion
    //region Filter & continuous
    /**
     * Filter the time axis by a function (and clears any existing filters first). The passed function will be called with each tick in time axis.
     * If the function returns `true`, the 'tick' is included otherwise it is filtered. If all ticks are filtered out
     * the time axis is considered invalid, triggering `invalidFilter` and then removing the filter.
     * @param {Function} fn The function to be called, it will receive an object with `startDate`/`endDate` properties, and `index` of the tick.
     * @param {Object} [thisObj] `this` reference for the function
     * @typings {Promise<any|null>}
     */
    filterBy(fn, thisObj = this) {
        const me = this;
        me.filters.clear();
        super.filterBy((tick, index) => fn.call(thisObj, tick.data, index));
    }
    filter() {
        const me = this;
        // Cache this to use in post filter processing
        me.viewportCenterDate = me.owner?.viewportCenterDate;
        const retVal = super.filter(...arguments);
        if (!me.maintainFilter && me.count === 0) {
            me.resumeEvents();
            me.trigger('invalidFilter');
            me.disableFilters();
        }
        return retVal;
    }
    disableFilters() {
        this.filters.forEach(f => f.disabled = true);
        this.filter();
    }
    triggerFilterEvent(event) {
        const
            me        = this,
            { owner } = me;
        if (!event.filters.count) {
            me.continuous = me.originalContinuous;
        }
        else {
            me.continuous = false;
        }
        // Filters has been applied (or cleared) but listeners are not informed yet, update tick cache to have start and
        // end dates correct when later redrawing events & header
        me.updateTickCache();
        // If we have filtered out ticks, the infinite scroll range will have to be recalculated
        if (owner?.infiniteScroll && !me.continuous) {
            owner.timeAxisViewModel.ion({
                update : () => {
                    owner.shiftToDate(me.viewportCenterDate, {
                        visibleDate : me.viewportCenterDate,
                        block       : 'center'
                    });
                },
                once : true
            });
        }
        super.triggerFilterEvent(event);
    }
    /**
     * Returns `true` if the time axis is continuous (will return `false` when filtered)
     * @property {Boolean}
     */
    get isContinuous() {
        return this.continuous !== false && !this.isFiltered;
    }
    //endregion
    //region Dates
    getAdjustedDates(startDate, endDate, forceAdjust = false) {
        const me = this;
        // If providing a 0 span range, add default range
        if (endDate && startDate - endDate === 0) {
            endDate = null;
        }
        startDate = startDate || me.startDate;
        endDate   = endDate || DH.add(startDate, me.defaultSpan, me.mainUnit);
        return me.autoAdjust || forceAdjust ? {
            startDate : me.floorDate(startDate, false, me.autoAdjust ? me.mainUnit : me.unit, 1),
            endDate   : me.ceilDate(endDate, false, me.autoAdjust ? me.mainUnit : me.unit, 1)
        } : {
            startDate,
            endDate
        };
    }
    /**
     * Method to get the current start date of the time axis.
     * @property {Date}
     */
    get startDate() {
        return this._start || (this.first ? new Date(this.first.startDate) : null);
    }
    set startDate(start) {
        this._start = DH.parse(start);
    }
    /**
     * Method to get a the current end date of the time axis
     * @property {Date}
     */
    get endDate() {
        return this._end || (this.last ? new Date(this.last.endDate) : null);
    }
    set endDate(end) {
        if (end) this._end = DH.parse(end);
    }
    // used in performance critical code for comparisons
    get startMS() {
        return this._startMS;
    }
    // used in performance critical code for comparisons
    get endMS() {
        return this._endMS;
    }
    // Floors a date and optionally snaps it to one of the following resolutions:
    // 1. 'resolutionUnit'. If param 'resolutionUnit' is passed, the date will simply be floored to this unit.
    // 2. If resolutionUnit is not passed: If date should be snapped relative to the timeaxis start date,
    // the resolutionUnit of the timeAxis will be used, or the timeAxis 'mainUnit' will be used to snap the date
    //
    // returns a copy of the original date
    // private
    floorDate(date, relativeToStart, resolutionUnit, incr) {
        relativeToStart = relativeToStart !== false;
        const
            me         = this,
            relativeTo = relativeToStart ? DH.clone(me.startDate) : null,
            increment  = incr || me.resolutionIncrement,
            unit       = resolutionUnit || (relativeToStart ? me.resolutionUnit : me.mainUnit),
            snap       = (value, increment) => Math.floor(value / increment) * increment;
        if (relativeToStart) {
            return DH.floor(date, { unit, magnitude : increment }, relativeTo);
        }
        const dt = DH.clone(date);
        if (unit === 'week') {
            const
                day      = dt.getDay() || 7,
                startDay = me.weekStartDay || 7;
            DH.add(DH.startOf(dt, 'day', false), day >= startDay ? startDay - day : -(7 - startDay + day), 'day', false);
            // Watch out for Brazil DST craziness (see test 028_timeaxis_dst.t.js)
            if (dt.getDay() !== startDay && dt.getHours() === 23) {
                DH.add(dt, 1, 'hour', false);
            }
        }
        else {
            // removes "smaller" units from date (for example minutes; removes seconds and milliseconds)
            DH.startOf(dt, unit, false);
            // day and year are 1-based so need to make additional adjustments
            const
                modifier     = ['day', 'year'].includes(unit) ? 1 : 0,
                useUnit      = unit === 'day' ? 'date' : unit,
                snappedValue = snap(DH.get(dt, useUnit) - modifier, increment) + modifier;
            DH.set(dt, useUnit, snappedValue);
        }
        return dt;
    }
    /**
     * Rounds the date to nearest unit increment
     * @private
     */
    roundDate(date, relativeTo, resolutionUnit = this.resolutionUnit, increment = this.resolutionIncrement || 1) {
        const
            me = this,
            dt = DH.clone(date);
        relativeTo = DH.clone(relativeTo || me.startDate);
        switch (resolutionUnit) {
            case 'week': {
                DH.startOf(dt, 'day', false);
                let distanceToWeekStartDay = dt.getDay() - me.weekStartDay,
                    toAdd;
                if (distanceToWeekStartDay < 0) {
                    distanceToWeekStartDay = 7 + distanceToWeekStartDay;
                }
                if (Math.round(distanceToWeekStartDay / 7) === 1) {
                    toAdd = 7 - distanceToWeekStartDay;
                }
                else {
                    toAdd = -distanceToWeekStartDay;
                }
                return DH.add(dt, toAdd, 'day', false);
            }
            case 'month': {
                const
                    nbrMonths     = DH.diff(relativeTo, dt, 'month') + DH.as('month', dt.getDay() / DH.daysInMonth(dt)), //*/DH.as('month', DH.diff(relativeTo, dt)) + (dt.getDay() / DH.daysInMonth(dt)),
                    snappedMonths = Math.round(nbrMonths / increment) * increment;
                return DH.add(relativeTo, snappedMonths, 'month', false);
            }
            case 'quarter':
                DH.startOf(dt, 'month', false);
                return DH.add(dt, 3 - (dt.getMonth() % 3), 'month', false);
            default: {
                const
                    duration        = DH.as(resolutionUnit, DH.diff(relativeTo, dt)),
                    // Need to find the difference of timezone offsets between relativeTo and original dates. 0 if timezone offsets are the same.
                    offset          = resolutionUnit === 'year' ? 0 : DH.as(resolutionUnit, relativeTo.getTimezoneOffset() - dt.getTimezoneOffset(), 'minute'),
                    // Need to add the offset to the whole duration, so the divided value will take DST into account
                    snappedDuration = Math.round((duration + offset) / increment) * increment;
                // Now when the round is done, we need to subtract the offset, so the result also will take DST into account
                return DH.add(relativeTo, snappedDuration - offset, resolutionUnit, false);
            }
        }
    }
    // private
    ceilDate(date, relativeToStart, resolutionUnit, increment) {
        const me = this;
        relativeToStart = relativeToStart !== false;
        increment       = increment || (relativeToStart ? me.resolutionIncrement : 1);
        const
            unit = resolutionUnit || (relativeToStart ? me.resolutionUnit : me.mainUnit),
            dt   = DH.clone(date);
        let doCall = false;
        switch (unit) {
            case 'minute':
                doCall = !DH.isStartOf(dt, 'minute');
                break;
            case 'hour':
                doCall = !DH.isStartOf(dt, 'hour');
                break;
            case 'day':
            case 'date':
                doCall = !DH.isStartOf(dt, 'day');
                break;
            case 'week':
                DH.startOf(dt, 'day', false);
                doCall = (dt.getDay() !== me.weekStartDay || !DH.isEqual(dt, date));
                break;
            case 'month':
                DH.startOf(dt, 'day', false);
                doCall = (dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;
            case 'quarter':
                DH.startOf(dt, 'day', false);
                doCall = (dt.getMonth() % 3 !== 0 || dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;
            case 'year':
                DH.startOf(dt, 'day', false);
                doCall = (dt.getMonth() !== 0 || dt.getDate() !== 1 || !DH.isEqual(dt, date));
                break;
        }
        if (doCall) {
            return DH.getNext(dt, unit, increment, me.weekStartDay);
        }
        return dt;
    }
    //endregion
    //region Ticks
    get include() {
        return this._include;
    }
    set include(include) {
        const me = this;
        me._include   = include;
        me.continuous = !include;
        if (!me.isConfiguring) {
            me.startDate = me._configuredStartDate;
            me.endDate   = me._configuredEndDate;
            me.internalOnReconfigure();
            me.trigger('includeChange');
        }
    }
    // Check if a certain date is included based on timeAxis.include rules
    processExclusion(startDate, endDate, unit) {
        const { include } = this;
        if (include) {
            return Object.entries(include).some(([includeUnit, rule]) => {
                if (!rule) {
                    return false;
                }
                const { from, to } = rule;
                // Including the closest smaller unit with a { from, to} rule should affect start & end of the
                // generated tick. Currently only works for days or smaller.
                if (DH.compareUnits('day', unit) >= 0 && DH.getLargerUnit(includeUnit) === unit) {
                    if (from) {
                        DH.set(startDate, includeUnit, from);
                    }
                    if (to) {
                        let stepUnit = unit;
                        // Stepping back base on date, not day
                        if (unit === 'day') {
                            stepUnit = 'date';
                        }
                        // Since endDate is not inclusive it points to the next day etc.
                        // Turns for example 2019-01-10T00:00 -> 2019-01-09T18:00
                        DH.set(endDate, {
                            [stepUnit]    : DH.get(endDate, stepUnit) - 1,
                            [includeUnit] : to
                        });
                    }
                }
                // "Greater" unit being included? Then we need to care about it
                // (for example excluding day will also affect hour, minute etc)
                if (DH.compareUnits(includeUnit, unit) >= 0) {
                    const datePart = (includeUnit === 'day' ? startDate.getDay() : DH.get(startDate, includeUnit));
                    if ((from && datePart < from) || (to && datePart >= to)) {
                        return true;
                    }
                }
            });
        }
        return false;
    }
    // Calculate constants used for exclusion when scaling within larger ticks
    initExclusion() {
        Object.entries(this.include).forEach(([unit, rule]) => {
            if (rule) {
                const { from, to } = rule;
                // For example for hour:
                // 1. Get the next bigger unit -> day, get ratio -> 24
                // 2. to 20 - from 8 = 12 hours visible each day. lengthFactor 24 / 12 = 2 means that each hour used
                // needs to represent 2 hours when drawn (to stretch)
                // |    ████    | -> |  ████████  |
                rule.lengthFactor = DH.getUnitToBaseUnitRatio(unit, DH.getLargerUnit(unit)) / (to - from);
                rule.lengthFactorExcl = DH.getUnitToBaseUnitRatio(unit, DH.getLargerUnit(unit)) / (to - from - 1);
                // Calculate weighted center to stretch around |   ██x█ |
                rule.center = from + from / (rule.lengthFactor - 1);
            }
        });
    }
    /**
     * Method generating the ticks for this time axis. Should return an array of ticks . Each tick is an object of the following structure:
     * ```
     * {
     *    startDate : ..., // start date
     *    endDate   : ...  // end date
     * }
     * ```
     * Take notice, that this function either has to be called with `start`/`end` parameters, or create those variables.
     *
     * To see it in action please check out our [TimeAxis](https://bryntum.com/products/scheduler/examples/timeaxis/) example and navigate to "Compressed non-working time" tab.
     *
     * @member {Function} generateTicks
     * @param {Date} axisStartDate The start date of the interval
     * @param {Date} axisEndDate The end date of the interval
     * @param {DurationUnit} unit The unit of the time axis
     * @param {Number} increment The increment for the unit specified.
     * @returns {Array|undefined} ticks The ticks representing the time axis, or no return value to use the default tick generation
     */
    updateGenerateTicks() {
        if (!this.isConfiguring) {
            this.reconfigure(this);
        }
    }
    _generateTicks(axisStartDate, axisEndDate, unit = this.unit, increment = this.increment) {
        const
            me            = this,
            ticks         = [],
            usesExclusion = Boolean(me.include);
        let intervalEnd,
            tickEnd,
            isExcluded,
            dstDiff                = 0,
            { startDate, endDate } = me.getAdjustedDates(axisStartDate, axisEndDate),
            tryRelyingOnExclusion  = false;
        me.tickCache = {};
        if (usesExclusion) {
            me.initExclusion();
        }
        while (startDate < endDate) {
            intervalEnd = DH.getNext(startDate, unit, increment, me.weekStartDay);
            // If `autoAdjustTimeAxis` is set to `false`, `intervalEnd` is forced to be the same as `endDate`.
            // It could happen that, after processing exclusion, the `intervalEnd` of last thick is lower than its `startDate`.
            // In this case, it is useful to continue the loop, and skip the next forced assignment of `endDate` to `intervalEnd` and check if,
            // after processing exclusion, the resulting `intervalEnd` complies with `endDate`.
            if (!me.autoAdjust && intervalEnd > endDate && !tryRelyingOnExclusion) {
                intervalEnd = new Date(endDate.getTime());
            }
            // Handle hourly increments crossing DST boundaries to keep the timescale looking correct
            // Only do this for HOUR resolution currently, and only handle it once per tick generation.
            if (unit === 'hour' && increment > 1 && ticks.length > 0 && dstDiff === 0) {
                const prev = ticks[ticks.length - 1];
                dstDiff = ((prev.startDate.getHours() + increment) % 24) - prev.endDate.getHours();
                if (dstDiff !== 0) {
                    // A DST boundary was crossed in previous tick, adjust this tick to keep timeaxis "symmetric".
                    intervalEnd = DH.add(intervalEnd, dstDiff, 'hour');
                }
            }
            isExcluded = false;
            if (usesExclusion) {
                tickEnd    = new Date(intervalEnd.getTime());
                isExcluded = me.processExclusion(startDate, intervalEnd, unit);
                if (tryRelyingOnExclusion) {
                    if (!me.autoAdjust && intervalEnd > endDate) {
                        startDate = tickEnd;
                        continue;
                    }
                }
                else if (intervalEnd < startDate) {
                    tryRelyingOnExclusion = true;
                    continue;
                }
            }
            else {
                tickEnd = intervalEnd;
            }
            if (!isExcluded) {
                ticks.push({
                    id      : (ticks.length + 1),
                    startDate,
                    endDate : intervalEnd
                });
                me.tickCache[startDate.getTime()] = ticks.length - 1;
            }
            startDate = tickEnd;
        }
        return ticks;
    }
    /**
     * How many ticks are visible across the TimeAxis.
     *
     * Usually, this is an integer because {@link #config-autoAdjust} means that the start and end
     * dates are adjusted to be on tick boundaries.
     * @property {Number}
     * @internal
     */
    get visibleTickTimeSpan() {
        const me = this;
        return me.isContinuous ? me.visibleTickEnd - me.visibleTickStart : me.count;
    }
    /**
     * Gets a tick "coordinate" representing the date position on the timescale. Returns -1 if the date is not part of the time axis.
     * @param {Date} date the date
     * @returns {Number} the tick position on the scale or -1 if the date is not part of the time axis
     */
    getTickFromDate(date) {
        const
            me     = this,
            ticks  = me.records,
            dateMS = date.getTime?.() ?? date;
        let begin = 0,
            end   = ticks.length - 1,
            middle, tick, tickStart, tickEnd;
        // Quickly eliminate out of range dates or if we have not been set up with a time range yet
        if (!ticks.length || dateMS < ticks[0].startDateMS || dateMS > ticks[end].endDateMS) {
            return -1;
        }
        if (me.isContinuous) {
            // Chop tick cache in half until we find a match
            while (begin < end) {
                middle = (begin + end + 1) >> 1;
                if (dateMS > ticks[middle].endDateMS) {
                    begin = middle + 1;
                }
                else if (dateMS < ticks[middle].startDateMS) {
                    end = middle - 1;
                }
                else {
                    begin = middle;
                }
            }
            tick      = ticks[begin];
            tickStart = tick.startDateMS;
            // Part way though, calculate the fraction
            if (dateMS > tickStart) {
                tickEnd = tick.endDateMS;
                begin += (dateMS - tickStart) / (tickEnd - tickStart);
            }
            return Math.min(Math.max(begin, me.visibleTickStart), me.visibleTickEnd);
        }
        else {
            for (let i = 0; i <= end; i++) {
                tickEnd = ticks[i].endDateMS;
                if (dateMS <= tickEnd) {
                    tickStart = ticks[i].startDateMS;
                    // date < tickStart can occur in filtered case
                    tick = i + (dateMS > tickStart ? (dateMS - tickStart) / (tickEnd - tickStart) : 0);
                    return tick;
                }
            }
        }
    }
    getSnappedTickFromDate(date) {
        const
            startTickIdx = Math.floor(this.getTickFromDate(date));
        return this.getAt(startTickIdx);
    }
    /**
     * Gets the time represented by a tick "coordinate".
     * @param {Number} tick the tick "coordinate"
     * @param {'floor'|'round'|'ceil'} [roundingMethod] Rounding method to use. 'floor' to take the tick (lowest header
     * in a time axis) start date, 'round' to round the value to nearest increment or 'ceil' to take the tick end date
     * @returns {Date} The date to represented by the tick "coordinate", or null if invalid.
     */
    getDateFromTick(tick, roundingMethod) {
        const me = this;
        if (tick === me.visibleTickEnd) {
            return me.endDate;
        }
        const
            wholeTick = Math.floor(tick),
            fraction  = tick - wholeTick,
            t         = me.getAt(wholeTick);
        if (!t) {
            return null;
        }
        const
            // if we've filtered timeaxis using filterBy, then we cannot trust to adjustedStart property and should use tick start
            start = wholeTick === 0 && me.isContinuous ? me.adjustedStart : t.startDate,
            // if we've filtered timeaxis using filterBy, then we cannot trust to adjustedEnd property and should use tick end
            end   = (wholeTick === me.count - 1) && me.isContinuous ? me.adjustedEnd : t.endDate;
        let date = DH.add(start, fraction * (end - start), 'millisecond');
        if (roundingMethod) {
            date = me[roundingMethod + 'Date'](date);
        }
        return date;
    }
    /**
     * Returns the ticks of the timeaxis in an array of objects with a "startDate" and "endDate".
     * @property {Scheduler.model.TimeSpan[]}
     */
    get ticks() {
        return this.records;
    }
    /**
     * Caches ticks and start/end dates for faster processing during rendering of events.
     * @private
     */
    updateTickCache(onlyStartEnd = false) {
        const me = this;
        if (me.count) {
            me._start   = me.first.startDate;
            me._end     = me.last.endDate;
            me._startMS = me.startDate.getTime();
            me._endMS   = me.endDate.getTime();
        }
        else {
            me._start = me._end = me._startMs = me._endMS = null;
        }
        // onlyStartEnd is true prior to clearing filters, to get start and end dates correctly during that process.
        // No point in filling tickCache yet in that case, it will be done after the filters are cleared
        if (!onlyStartEnd) {
            me.tickCache = {};
            me.forEach((tick, i) => me.tickCache[tick.startDate.getTime()] = i);
        }
    }
    //endregion
    //region Axis
    /**
     * Returns true if the passed date is inside the span of the current time axis.
     * @param {Date} date The date to query for
     * @returns {Boolean} true if the date is part of the time axis
     */
    dateInAxis(date, inclusiveEnd = false) {
        const
            me        = this,
            axisStart = me.startDate,
            axisEnd   = me.endDate;
        // Date is between axis start/end and axis is not continuous - need to perform better lookup
        if (me.isContinuous) {
            return inclusiveEnd ? DH.betweenLesserEqual(date, axisStart, axisEnd) : DH.betweenLesser(date, axisStart, axisEnd);
        }
        else {
            const length = me.count;
            let tickStart, tickEnd, tick;
            for (let i = 0; i < length; i++) {
                tick      = me.getAt(i);
                tickStart = tick.startDate;
                tickEnd   = tick.endDate;
                if ((inclusiveEnd && date <= tickEnd) || (!inclusiveEnd && date < tickEnd)) {
                    return date >= tickStart;
                }
            }
        }
        return false;
    }
    /**
     * Returns true if the passed timespan is part of the current time axis (in whole or partially).
     * @param {Date} start The start date
     * @param {Date} end The end date
     * @returns {Boolean} true if the timespan is part of the timeaxis
     */
    timeSpanInAxis(start, end) {
        const me = this;
        if (!end || end.getTime() === start.getTime()) {
            return this.dateInAxis(start, true);
        }
        if (me.isContinuous) {
            return DH.intersectSpans(start, end, me.startDate, me.endDate);
        }
        return (start < me.startDate && end > me.endDate) || me.getTickFromDate(start) !== me.getTickFromDate(end);
    }
    // Accepts a TimeSpan model (uses its cached MS values to be a bit faster during rendering)
    isTimeSpanInAxis(timeSpan) {
        const
            me                         = this,
            { startMS, endMS }         = me,
            { startDateMS }            = timeSpan,
            endDateMS                  = timeSpan.endDateMS ?? timeSpan.meta.endDateCached;
        // only consider fully scheduled ranges
        if (!startDateMS || !endDateMS) {
            return false;
        }
        if (endDateMS === startDateMS) {
            return me.dateInAxis(timeSpan.startDate, true);
        }
        if (me.isContinuous) {
            return endDateMS > startMS && startDateMS < endMS;
        }
        const
            startTick = me.getTickFromDate(startDateMS),
            endTick   = me.getTickFromDate(endDateMS);
        // endDate is not inclusive
        if (
            (startTick === me.count && DH.isEqual(timeSpan.startDate, me.last.endDate)) ||
            (endTick === 0 && DH.isEqual(timeSpan.endDate, me.first.startDate))
        ) {
            return false;
        }
        return (
            // Spanning entire axis
            (startDateMS < startMS && endDateMS > endMS) ||
            // Unintentionally 0 wide (ticks excluded or outside)
            startTick !== endTick
        );
    }
    //endregion
    //region Iteration
    /**
     * Calls the supplied iterator function once per interval. The function will be called with four parameters, startDate endDate, index, isLastIteration.
     * @internal
     * @param {DurationUnit} unit The unit to use when iterating over the timespan
     * @param {Number} increment The increment to use when iterating over the timespan
     * @param {Function} iteratorFn The function to call
     * @param {Object} [thisObj] `this` reference for the function
     */
    forEachAuxInterval(unit, increment = 1, iteratorFn, thisObj = this) {
        const end = this.endDate;
        let dt = this.startDate,
            i  = 0,
            intervalEnd;
        if (dt > end) {
            throw new Error('Invalid time axis configuration');
        }
        while (dt < end) {
            intervalEnd = DH.min(DH.getNext(dt, unit, increment, this.weekStartDay), end);
            iteratorFn.call(thisObj, dt, intervalEnd, i, intervalEnd >= end);
            dt = intervalEnd;
            i++;
        }
    }
    //endregion
}
TimeAxis._$name = 'TimeAxis';