import Exporter from './Exporter.js';
import { Orientation, PaperFormat, RowsRange } from '../Utils.js';
/**
 * @module Grid/feature/export/exporter/MultiPageExporter
 */
/**
 * A multiple page exporter. Used by the {@link Grid.feature.export.PdfExport} feature to export to multiple pages. You
 * do not need to use this class directly.
 *
 * ### Extending exporter
 *
 * ```javascript
 * class MyMultiPageExporter extends MultiPageExporter {
 *     // type is required for exporter
 *     static get type() {
 *         return 'mymultipageexporter';
 *     }
 *
 *     get stylesheets() {
 *         const stylesheets = super.stylesheets;
 *
 *         stylesheets.forEach(styleNodeOrLinkTag => doSmth(styleNodeOrLinkTag))
 *
 *         return stylesheets;
 *     }
 * }
 *
 * const grid = new Grid({
 *     features : {
 *         pdfExport : {
 *             // this export feature is configured with only one exporter
 *             exporters : [MyMultiPageExporter]
 *         }
 *     }
 * });
 *
 * // run export with the new exporter
 * grid.features.pdfExport.export({ exporter : 'mymultipageexporter' });
 * ```
 *
 * @classtype multipage
 * @extends Grid/feature/export/exporter/Exporter
 */
export default class MultiPageExporter extends Exporter {
    static get $name() {
        return 'MultiPageExporter';
    }
    static get type() {
        return 'multipage';
    }
    static get title() {
        // In case locale is missing exporter is still distinguishable
        return this.L('L{multipage}');
    }
    static get exportingPageText() {
        return 'L{exportingPage}';
    }
    //region State management
    async stateNextPage({ client, rowsRange }) {
        const { exportMeta } = this;
        ++exportMeta.currentPage;
        ++exportMeta.verticalPosition;
        // If last row is exported, mark this page as a last vertical page
        if (exportMeta.lastRowIndex >= exportMeta.finishRowIndex) {
            exportMeta.verticalPages = exportMeta.verticalPosition;
            exportMeta.totalPages = exportMeta.verticalPages * exportMeta.horizontalPages;
        }
        // If last row is not exported yet, make sure we don't cut vertical pages
        else {
            exportMeta.verticalPages = Math.max(exportMeta.verticalPages, exportMeta.verticalPosition + 1);
        }
        delete exportMeta.lastExportedRowBottom;
        // If current vertical position is greater than max vertical pages, switch to next column
        if (exportMeta.verticalPosition >= exportMeta.verticalPages && exportMeta.horizontalPosition < exportMeta.horizontalPages - 1) {
            Object.assign(exportMeta, {
                verticalPosition   : 0,
                horizontalPosition : exportMeta.horizontalPosition + 1,
                lastTop            : 0,
                lastRowIndex       : rowsRange === RowsRange.visible ? client.rowManager.firstVisibleRow.dataIndex : 0
            });
        }
    }
    //endregion
    //region Preparation
    async prepareComponent(config) {
        await super.prepareComponent(config);
        const
            me              = this,
            { exportMeta }  = me,
            {
                client,
                headerTpl,
                footerTpl,
                alignRows,
                rowsRange
            }               = config,
            paperFormat     = PaperFormat[config.paperFormat],
            isPortrait      = config.orientation === Orientation.portrait,
            paperWidth      = me.getPaperWidth(paperFormat, isPortrait),
            paperHeight     = me.getPaperHeight(paperFormat, isPortrait),
            pageWidth       = me.inchToPx(paperWidth),
            pageHeight      = me.inchToPx(paperHeight),
            onlyVisibleRows = rowsRange === RowsRange.visible,
            horizontalPages = Math.ceil(exportMeta.totalWidth / pageWidth);
        // To estimate amount of pages correctly we need to know height of the header/footer on every page
        let contentHeight = pageHeight;
        if (headerTpl) {
            contentHeight -= me.measureElement(headerTpl({
                totalWidth  : exportMeta.totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-header');
        }
        if (footerTpl) {
            contentHeight -= me.measureElement(footerTpl({
                totalWidth  : exportMeta.totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-footer');
        }
        let totalHeight, verticalPages, totalRows = client.store.count;
        if (onlyVisibleRows) {
            totalRows = me.getVisibleRowsCount(client);
            totalHeight = exportMeta.totalHeight + client.headerHeight + client.footerHeight + client.bodyHeight;
            exportMeta.finishRowIndex = client.rowManager.lastVisibleRow.dataIndex;
        }
        else {
            totalHeight = exportMeta.totalHeight + client.headerHeight + client.footerHeight + client.scrollable.scrollHeight;
            exportMeta.finishRowIndex = client.store.count - 1;
        }
        // alignRows config specifies if rows should be always fully visible. E.g. if row doesn't fit on the page, it goes
        // to the top of the next page
        if (alignRows && !onlyVisibleRows) {
            // we need to estimate amount of vertical pages for case when we only put row on the page if it fits
            // first we need to know how many rows would fit one page, keeping in mind first page also contains header
            // This estimation is loose, because row height might differ much between pages
            const
                rowHeight       = client.rowManager.rowOffsetHeight,
                rowsOnFirstPage = Math.floor((contentHeight - client.headerHeight) / rowHeight),
                rowsPerPage     = Math.floor(contentHeight / rowHeight),
                remainingRows   = totalRows - rowsOnFirstPage;
            verticalPages = 1 + Math.ceil(remainingRows / rowsPerPage);
        }
        else {
            verticalPages = Math.ceil(totalHeight / contentHeight);
        }
        Object.assign(exportMeta, {
            paperWidth,
            paperHeight,
            pageWidth,
            pageHeight,
            horizontalPages,
            verticalPages,
            totalHeight,
            contentHeight,
            totalRows,
            totalPages         : horizontalPages * verticalPages,
            currentPage        : 0,
            verticalPosition   : 0,
            horizontalPosition : 0,
            lastTop            : 0,
            lastRowIndex       : onlyVisibleRows ? client.rowManager.firstVisibleRow.dataIndex : 0
        });
    }
    //endregion
    async renderRows(config) {
        const
            me                 = this,
            { exportMeta }     = me,
            {
                client,
                alignRows,
                repeatHeader
            }                  = config,
            {
                verticalPosition,
                contentHeight,
                lastRowIndex,
                fakeRow
            }                  = exportMeta,
            rows               = [],
            // If we are repeating header we've already taken header height into account when setting content height
            clientHeaderHeight = repeatHeader ? 0 : client.headerHeight,
            { store }          = client,
            hasMergeCells      = client.hasActiveFeature('mergeCells');
        let index           = lastRowIndex + (!alignRows || verticalPosition === 0 ? 0 : 1),
            lastTop         = 0,
            remainingHeight = contentHeight,
            previousTop;
        // first exported page contains header
        if (verticalPosition === 0) {
            remainingHeight -= clientHeaderHeight;
        }
        while (remainingHeight > 0) {
            fakeRow.render(index, store.getAt(index), true, false, true);
            if (alignRows && remainingHeight < fakeRow.offsetHeight) {
                break;
            }
            else {
                previousTop = lastTop;
                exportMeta.lastExportedRowBottom = lastTop = fakeRow.translate(lastTop);
                exportMeta.lastRowIndex = fakeRow.dataIndex;
                remainingHeight -= fakeRow.offsetHeight;
                me.collectRow(fakeRow);
                index++;
                // Push an object with data required to build merged cell
                rows.push({
                    top          : fakeRow.top,
                    bottom       : fakeRow.bottom,
                    offsetHeight : fakeRow.offsetHeight,
                    dataIndex    : fakeRow.dataIndex
                });
                // last row is rendered, check if it is fully visible
                if (fakeRow.dataIndex === exportMeta.finishRowIndex) {
                    // empty space left - row is completely visible, stop rendering, raise a flag
                    if (remainingHeight >= 0) {
                        break;
                    }
                    // last row is rendered, but it did not fit the view
                    else {
                        // row cannot be fit
                        if (fakeRow.offsetHeight > contentHeight) {
                            // If row is higher than the page we obviously cannot fit it, skip for now
                            // https://github.com/bryntum/support/issues/9241
                            exportMeta.lastRowIndex--;
                        }
                        // remaining height is <0, meaning row is overflowing and can be fit to the view.
                        // in this case we need to repeat it on the next page, if we're not aligning rows
                        else {
                            exportMeta.lastRowIndex--;
                        }
                    }
                }
            }
        }
        if (hasMergeCells) {
            me.renderMergedCells(config, lastRowIndex, index, rows);
        }
        exportMeta.lastTop = alignRows ? lastTop : previousTop;
        // Calculate exact grid height according to the last exported row
        exportMeta.exactGridHeight = fakeRow.bottom + client.footerContainer.offsetHeight + ((verticalPosition === 0 || repeatHeader) ? clientHeaderHeight : 0);
        await me.onRowsCollected(rows, config);
    }
    async buildPage(config) {
        const
            me             = this,
            { exportMeta } = me,
            {
                client,
                headerTpl,
                footerTpl
            }              = config,
            {
                totalWidth,
                totalPages,
                currentPage,
                subGrids
            }              = exportMeta;
        // Rows are stored in shared state object, need to clean it before exporting next page
        Object.values(subGrids).forEach(subGrid => subGrid.rows = []);
        // With variable row height total height might change after scroll, update it
        // to show content completely on the last page
        if (config.rowsRange === RowsRange.all) {
            exportMeta.totalHeight = client.height - client.bodyHeight + client.scrollable.scrollHeight - me.getVirtualScrollerHeight(client);
        }
        let header, footer;
        // Measure header and footer height
        if (headerTpl) {
            header = me.prepareHTML(headerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        if (footerTpl) {
            footer = me.prepareHTML(footerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        await me.renderRows(config);
        const html = me.buildPageHtml(config);
        return { html, header, footer };
    }
    async onRowsCollected() {}
    buildPageHtml() {
        const
            me           = this,
            { subGrids } = me.exportMeta;
        // Now when rows are collected, we need to add them to exported grid
        let html = me.prepareExportElement();
        Object.values(subGrids).forEach(({ placeHolder, rows, mergedCellsHtml }) => {
            const placeHolderText = placeHolder.outerHTML;
            let contentHtml = rows.reduce((result, row) => {
                result += row[0];
                return result;
            }, '');
            if (mergedCellsHtml?.length) {
                contentHtml += `<div class="b-grid-merged-cells-container">${mergedCellsHtml.join('')}</div>`;
            }
            html = html.replace(placeHolderText, contentHtml);
        });
        return html;
    }
}
// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
MultiPageExporter.prototype.pagesExtractor = async function * pagesExtractor(config) {
    const
        me = this,
        {
            exportMeta,
            stylesheets
        }  = me,
        {
            totalWidth,
            totalPages,
            paperWidth,
            paperHeight,
            realPaperWidth,
            realPaperHeight,
            title
        }  = exportMeta,
        isPrint = config.useBrowserPrint;
    let currentPage;
    while (exportMeta.lastRowIndex < exportMeta.finishRowIndex) {
        currentPage = exportMeta.currentPage;
        me.trigger('exportStep', {
            text     : me.L(MultiPageExporter.exportingPageText, { currentPage, totalPages }),
            progress : Math.round(((currentPage + 1) / totalPages) * 90)
        });
        const { html, header, footer } = await me.buildPage(config);
        // TotalHeight might change in case of variable row heights
        // Move exported content in the visible frame
        const styles = [
            ...stylesheets,
            `
                <style>
                    .b-page-wrap {
                        width: ${paperWidth}in;
                        height: ${paperHeight}in;
                    }
                    .b-page-${currentPage} #${config.client.id} {
                        height: ${exportMeta.exactGridHeight}px !important;
                        width: ${totalWidth}px !important;
                    }
                    .b-page-${currentPage} .b-export-body .b-export-viewport {
                        transform: translate(${-paperWidth * exportMeta.horizontalPosition}in, 0);
                    }
                    ${exportMeta.verticalPosition === 0 ? '' : `.b-page-${currentPage} header.b-grid-header-container {
                        display: none;
                    }`}
                </style>
            `];
        await me.stateNextPage(config);
        yield {
            html : me.pageTpl({
                html,
                title,
                header,
                footer,
                styles,
                paperWidth,
                paperHeight,
                realPaperWidth,
                realPaperHeight,
                currentPage,
                isPrint
            })
        };
    }
};
MultiPageExporter._$name = 'MultiPageExporter';